import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Component/ToastAlert";

const EmailSetting = () => {
  const mail_transport = useRef();
  const mail_host = useRef();
  const mail_port = useRef();
  const mail_encryption = useRef();
  const mail_username = useRef();
  const mail_password = useRef();
  const mail_from = useRef();
  const app_name_show_in_mail = useRef();

  // Initial table data
  const [updateData, setUpdateData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/manage-site/email-setting`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setUpdateData(res.data?.data);
      });
  };

  // form submit to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PATCH");

    formdata.append("mail_transport", mail_transport.current.value);
    formdata.append("mail_host", mail_host.current.value);
    formdata.append("mail_port", mail_port.current.value);
    formdata.append("mail_encryption", mail_encryption.current.value);
    formdata.append("mail_username", mail_username.current.value);
    formdata.append("mail_password", mail_password.current.value);
    formdata.append("mail_from", mail_from.current.value);
    formdata.append(
      "app_name_show_in_mail",
      app_name_show_in_mail.current.value
    );

    axios
      .post(
        `${BACKEND_BASE_URL}admin/manage-site/email-setting/update/${1}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data.message,
          });
          renderAllInfo();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                {/* <h5>FAQ Categories</h5> */}
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Email Configuration</h5>
                  {/* <Link to="/admin/blog">
                    <Button variant="success" size="sm">
                      View All Blogs &nbsp;
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                {/* <div className="card-header">
                  <Link to="/admin/blogcategory/create">
                    <Button variant="success" size="sm">
                      Add New &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div> */}

                <Form onSubmit={updatedData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add mail_transport */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Transport&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_transport}
                              defaultValue={updateData?.mail_transport}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Host&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_host}
                              defaultValue={updateData?.mail_host}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Port&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_port}
                              defaultValue={updateData?.mail_port}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Encryption&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_encryption}
                              defaultValue={updateData?.mail_encryption}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Username&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_username}
                              defaultValue={updateData?.mail_username}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail Password&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_password}
                              defaultValue={updateData?.mail_password}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Mail From&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={mail_from}
                              defaultValue={updateData?.mail_from}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              App name show in mail&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              ref={app_name_show_in_mail}
                              defaultValue={updateData?.app_name_show_in_mail}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSetting;
