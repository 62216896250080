import axios from "axios";
import React, { useRef } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Component/Cookie/Get.Cookie";
import { ToastSuccess } from "../../../../Component/ToastAlert";

const NewTicket = () => {
  const subject = useRef();
  const message = useRef();
  const attachment = useRef();
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("subject", subject.current.value);
    formdata.append("message", message.current.value);
    formdata.append("attachment", attachment.current.files[0]);

    axios
      .post(`${BACKEND_BASE_URL}user/support/ticket/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status == "ok") {
          ToastSuccess.fire({
            icon: "success",
            title: "Ticket added...!",
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Create Ticket</h5>
                  <Link to="/user/ticket">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Back &nbsp;
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 p-4">
          {/* <div className="card"> */}
          {/* <div className="card-body"> */}
          {/* <div className="col-lg-12"> */}
          <Form onSubmit={storeData}>
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="row py-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      className="mb-3"
                    >
                      <Form.Label className="label fw-bold">
                        Subject&nbsp;{""}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Subject"
                        ref={subject}
                      />
                    </Form.Group>

                    {/* Message */}
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      className="mb-3"
                    >
                      <Form.Label className="label fw-bold">
                        Message&nbsp;{""}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control as="textarea" rows={4} ref={message} />
                    </Form.Group>
                    {/* Attachment */}
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      className="mb-3"
                    >
                      <Form.Label className="label fw-bold">
                        Attachment&nbsp;{""}
                        <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        type="file"
                        ref={attachment}
                        //   onChange={handleImgPreview}
                      />

                      {/* {files.map((file, key) => {
                          return (
                            <div key={key} className="Row">
                              <span className="Filename">
                                <img
                                  width={80}
                                  height={50}
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                />
                              </span>
                            </div>
                          );
                        })} */}

                      <Form.Control.Feedback type="invalid">
                        Choose File
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* <p className="text-danger">{imgError}</p> */}

                    <Button
                      style={{ background: "#0289ff" }}
                      type="submit"
                      className="btn-submit mt-5 rounded-3 border-0"
                    >
                      <FiSave /> &nbsp; Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default NewTicket;
