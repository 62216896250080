/*===================
    Product Page
=====================
1. variable 
2. main function home()

  a. filter
    i. filter_header
    ii. filter_body
  b. product_content
    i. single_product

================*/

import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Form,
  Offcanvas,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import Footer from "../../Component/Common/Footer/Footer";
import Header from "../../Component/Common/Header/Header";
import "./HomeProduct.css";
import product_test from "../../Assets/test.jpg";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { USerContext } from "../../App";
import { BACKEND_BASE_URL, fileUrl } from "../../Utils/GlobalVariables";
import axios from "axios";
import { Pagination } from "@mui/material";
import { DotLoader } from "../../Component/Custom Loader/CustomLoader";

const CategoryProducts = () => {
  const { cat_slug } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const { wishlist, setWishlist } = useContext(USerContext);
  // notification function
  const [show, setShow] = useState(false);
  const wishlistCounter = () => {
    setWishlist(wishlist + 1);
    setShow(true);
  };

  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  //all catSubCatData data
  const [categoryProductsData, setCatProducts] = useState([]);
  const [categoryName, setCategoryName] = useState({});
  // console.log(categoryName);
  // Get All catSubCatData Info
  const renderCatProducts = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BACKEND_BASE_URL}products/category-wise-products/${cat_slug}?page=${page}`
      )
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setCatProducts(res.data?.data?.data);
        setCategoryName(res.data?.category_name);

        setFirstPage(res.data?.data?.from);
        setLastPage(res.data?.data?.last_page);
      });
  };

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    renderCatProducts();
  }, [cat_slug, page]);
  return (
    <>
      <Header />
      {/*======== dynamically title and meta tag for seo start ========*/}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fantabulous | Products</title>
      </Helmet>

      {/* jodi data pay */}
      {!isLoading && !error && categoryProductsData?.length > 0 && (
        <div className="product">
          {/* filter */}

          {/* filter_header */}
          <div className="filter_header py-4 px-4">
            <div className="d-flex flex-wrap justify-content-between">
              <p className="m-0 font_size_14 fw_500">
                Category: {categoryName?.name}
              </p>

              <p
                className="m-0 font_size_14 filter fw_500 pointer"
                onClick={() => setShowFilter(!showFilter)}
              >
                {showFilter ? "Close Filters" : "Filter Availability "}
                <FaChevronDown
                  className={`ms-3 ${showFilter ? "rotate" : ""}`}
                  size={16}
                />
              </p>
            </div>
          </div>

          {/* filter body */}

          {showFilter && (
            <div className="filter_body custom_container container_fluid">
              <div className="filter_wrapper py-3">
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Filter by category :</Accordion.Header>
                      <Accordion.Body>
                        <ul className="l_s_none p-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Women's Collection"
                              id="category1"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Men's Collection"
                              id="category2"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="New Collection"
                              id="category3"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Shoes Collection"
                              id="category4"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="T-Shirt Collection"
                              id="category4"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Filter by size :</Accordion.Header>
                      <Accordion.Body>
                        <ul className="l_s_none p-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Extra small size"
                              id="size1"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Small size"
                              id="size2"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Medium size"
                              id="size3"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Large size"
                              id="size4"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Extra large size"
                              id="size5"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Filter by color :</Accordion.Header>
                      <Accordion.Body>
                        <ul className="l_s_none p-0">
                          <li>
                            <Form.Check type="checkbox" label="Red" id="Red" />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Blue"
                              id="Blue"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Yellow"
                              id="Yellow"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="Black"
                              id="Black"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="White"
                              id="White"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="content">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Filter by price :</Accordion.Header>
                      <Accordion.Body>
                        <ul className="l_s_none p-0">
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="$10 - $100 (10)"
                              id="price5"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="$100 - $150 (5)"
                              id="price1"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="$150 - $200 (15)"
                              id="price2"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="$200 - $250 (20)"
                              id="price3"
                            />
                          </li>
                          <li>
                            <Form.Check
                              type="checkbox"
                              label="$250 - $300 (30)"
                              id="price4"
                            />
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {/* notification  */}
          <ToastContainer position="top-end" className="p-3">
            <Toast
              bg="success"
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header className="rounded-0 border-0 ">
                <strong className="me-auto">
                  Successfully Added To Wishlist
                </strong>
              </Toast.Header>
            </Toast>
          </ToastContainer>

          <div className="product_content  d-flex flex-wrap ">
            {/* single_product */}

            {categoryProductsData?.map((categoryProductsData, i) => (
              <div
                className="single_product  pb-4"
                key={categoryProductsData?.id}
              >
                <Link
                  to={`/product-details/${categoryProductsData?.id}`}
                  className="underline_none cl_dark"
                >
                  <div>
                    {/* <img className="" src={product_test} alt="" /> */}
                    <img
                      className=""
                      src={`${fileUrl}${categoryProductsData?.featured_image}`}
                      alt=""
                    />
                    {/* <img
                      className=""
                      style={{
                        height: "475px",
                        width: "475px",
                      }}
                      src={`${fileUrl}${categoryProductsData?.featured_image}`}
                      alt=""
                    /> */}
                  </div>
                </Link>
                <div className="d-flex p-2">
                  <div className="left">
                    <Link to="/product-details" className="underline_none">
                      <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                        {categoryProductsData?.name}
                      </p>
                    </Link>
                    <p className="mb-0 cl_lightGrey">
                      {categoryProductsData.categories.name}
                      <br />
                      {categoryProductsData.brand.name}
                    </p>
                    <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                      {categoryProductsData.price_including_tax > 0 ? (
                        <>
                          ${categoryProductsData?.price_including_tax}{" "}
                          <span className="font_size_16 fw_200">
                            (including vat)
                          </span>
                        </>
                      ) : (
                        <>${categoryProductsData?.current_price} </>
                      )}
                    </p>
                  </div>

                  <div className="right ms-auto ">
                    <div className="icon p-2 d-flex justify-content-center align-items-center">
                      <AiOutlineHeart size={16} onClick={wishlistCounter} />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}

            {/* single_product */}
            {/* <div className="single_product  pb-4">
            <Link to="/product-details" className="underline_none cl_dark">
              <div>
                <img className="" src={product_test} alt="" />
              </div>
            </Link>
            <div className="d-flex p-2">
              <div className="left">
                <Link to="/product-details" className="underline_none">
                  <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                    Ballerine 2, 2014
                  </p>
                </Link>
                <p className="mb-0 cl_lightGrey">
                  Oil on Canvas
                  <br />
                  28x20in
                </p>
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">$2,100</p>
              </div>

              <div className="right ms-auto ">
                <div className="icon p-2 d-flex justify-content-center align-items-center">
                  <AiOutlineHeart size={16} onClick={wishlistCounter} />
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}

      {/* jodi data na pay */}
      {isLoading && !error && (
        <>
          <div className="d-flex justify-content-center py-5 my-5">
            {/* <PulseLoader /> */}
            <DotLoader />
          </div>
        </>
      )}

      {!isLoading && !error && categoryProductsData?.length < 1 && (
        <>
          <div className="text-center text-danger py-5 my-5">
            <h1>No data Found</h1>
          </div>
        </>
      )}

      {/* paginate */}
      {!isLoading && !error && categoryProductsData?.length > 0 && (
        <>
          <div className="pagination_box d-flex justify-content-center mt-5">
            <Pagination
              className="paggination  py-3 px-5 flex-wrap"
              count={lastPage}
              defaultPage={page}
              selected={page}
              siblingCount={1}
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default CategoryProducts;
