/*===================
    cart  INDEX  
=====================
1. variable 
2. main function

  a. Header component
  b. cart
    i.  notification
    ii. cart table
  c. footer 

================*/

// variable
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Footer from "../../Component/Common/Footer/Footer";
import Header from "../../Component/Common/Header/Header";
import "./Cart.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import getCookie from "../../Component/Cookie/Get.Cookie";
import axios from "axios";
import { BACKEND_BASE_URL, fileUrl } from "../../Utils/GlobalVariables";
import Cookie from "cookie-universal";
import { ToastSuccess } from "../../Component/ToastAlert";
import Swal from "sweetalert2";

// main function
const Cart = () => {
  const cookies = Cookie();
  const navigate = useNavigate();

  // Initial cart count data
  const [cart_count, setCartCount] = useState();
  const [cart_items, setCartItems] = useState();
  // Get Cart Count Info
  const renderCartInfo = async () => {
    if (getCookie("user_id")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}user/cart/view-items/${getCookie("user_id")}`,
          {
            headers: {
              // Authorization: `Bearer ${getCookie("user_access_token")}`,
            },
          }
        )
        .then((res) => {
          setCartItems(res?.data?.data);
          setCartCount(res?.data?.cart_count);
          if (res.data.cart_count) {
            cookies.set("cart_count", res.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
          }
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}user/cart/view-items/${getCookie(
            "VISITOR_UNIQUE_ID"
          )}`,
          {
            headers: {
              // Authorization: `Bearer ${getCookie("user_access_token")}`,
            },
          }
        )
        .then((res) => {
          setCartItems(res?.data?.data);
          setCartCount(res?.data?.cart_count);
          if (res.data.cart_count) {
            cookies.set("cart_count", res.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
          }
        });
    }
  };

  const checkoutPage = () => {
    if (getCookie("user_access_token")) {
      navigate("/checkout");
    } else {
      ToastSuccess.fire({
        icon: "warning",
        title: "You must log in first.",
      });
    }
  };

  const qtyUpdatePlus = async (id) => {
    await axios
      .put(
        `${BACKEND_BASE_URL}user/cart/update-items-qty/${id}`,
        {},
        {
          headers: {
            // Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
        }
        renderCartInfo();
      });
  };

  const qtyUpdateMinus = async (id) => {
    await axios
      .put(
        `${BACKEND_BASE_URL}user/cart/update-items-qty-minus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
        } else if (response.data?.status == 201) {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        }
        renderCartInfo();
      });
  };

  const renderCartRemoveItems = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}user/cart/remove-items/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            cookies.set("cart_count", response.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderCartInfo();
          }
        });
    }
  };

  // const { cart, setCart } = useContext(USerContext);
  // // notification function
  // const [show, setShow] = useState(false);

  // const cartCounterIncrement = () => {

  //   setCart(cart + 1);
  //   setShow(true);
  // };

  // const cartCounterDecrement = () => {
  //   if (cart > 0) {
  //     setCart(cart - 1);
  //   }
  //   setShow(true);
  // };
  useEffect(() => {
    renderCartInfo();
  }, []);
  return (
    <>
      <Header />
      <div className="cart sesction_pt sesction_pb">
        {/* notification */}
        {/* <ToastContainer position="top-end" className="p-3">
          <Toast
            bg="success"
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className="rounded-0 border-0 ">
              <strong className="me-auto">Cart Updated</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer> */}

        <div className="custom_container">
          {/* cart_table */}
          <div className="cart_table mb-2">
            <Table responsive>
              <thead>
                <tr>
                  <th>PRODUCT NAME</th>
                  <th></th>
                  <th>SKU</th>
                  <th>UNIT PRICE</th>
                  <th>QUANTITY</th>
                  <th>TOTAL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cart_items?.map((data, i) => (
                  <tr key={data.id}>
                    <td className="table_image">
                      <img
                        className="img-fluid"
                        src={`${fileUrl}${data?.product?.featured_image}`}
                        alt=""
                      />
                    </td>
                    <td>
                      <h5 className="font_size_14 fw_700 ">
                        {data.product.name}
                      </h5>
                    </td>
                    <td className="font_size_14 fw_600 ">{data.product.sku}</td>
                    <td className="font_size_14 fw_600 ">
                      {data.product.price_including_tax > 0 ? (
                        <>
                          ${data?.product.price_including_tax}{" "}
                          <span className="font_size_16 fw_200">
                            (including vat)
                          </span>
                        </>
                      ) : (
                        <>${data?.product.current_price} </>
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-between  counter">
                        <button
                          className="border-0"
                          onClick={() => qtyUpdateMinus(data.id)}
                        >
                          -
                        </button>
                        <input type="text" value={data.qty} />
                        <button
                          className="border-0"
                          onClick={() => qtyUpdatePlus(data.id)}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className="font_size_14 fw_600 ">
                      {data.product.price_including_tax > 0 ? (
                        <>${data?.product.price_including_tax * data.qty}</>
                      ) : (
                        <>${data?.product.current_price * data.qty} </>
                      )}
                    </td>
                    <td className="font_size_14 fw_600 cl_lightGrey ">
                      <AiOutlineCloseCircle
                        size={30}
                        className="pointer"
                        onClick={() => renderCartRemoveItems(data.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="checkout_button d-flex justify-content-end">
            {/* <Link to="/checkout" className="underline_none"> */}
            <button className="ms-auto" onClick={checkoutPage}>
              Checkout
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
