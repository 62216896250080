// import * as FaIcons from "react-icons/fa";
// import * as SiIcons from "react-icons/si";
// import * as MdIcons from "react-icons/md";
// import * as IoIcons from "react-icons/io";
import { FaListAlt, FaProductHunt, FaWindowRestore } from "react-icons/fa";
import { IoMdResize } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { SiBrandfolder } from "react-icons/si";
import { Link } from "react-router-dom";

import "./admindashboard.css";
// import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";

const AdminDashboard = () => {
  return (
    <div className="">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}
        <div className="main_section">
          <div className="row py-3">
            {/* <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaWindowRestore className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Orders</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">1</h1>
                    <p>
                      <Link to="order" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaListAlt className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Category</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">1</h1>
                    <p>
                      <Link to="category" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaListAlt className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Brands</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">2</h1>
                    <p>
                      <Link to="brand" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <SiBrandfolder className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Brands</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">3</h1>
                    <p>
                      <Link to="brand" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <IoMdResize className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Sizes</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">3</h1>
                    <p>
                      <Link to="size" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <MdGroups className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>All Customers</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">4</h1>
                    <p>
                      <Link to="all-customers" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaProductHunt className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Products</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">5</h1>
                    <p>
                      <Link to="product" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
