import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Form } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { Autocomplete, TextField } from "@mui/material";
import getCookie from "../../Component/Cookie/Get.Cookie";
import { ToastSuccess } from "../../Component/ToastAlert";
import { BACKEND_BASE_URL, fileUrl } from "../../Utils/GlobalVariables";

function PopularCategory() {
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}admin/manage-site/home-page-setting/popular-category`,
        {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )
      .then((res) => {
        setTableData(res.data?.data);
      });
  };

  // product data for add to campaign
  const [aLLCategoryList, setALLCategoryList] = useState([]);
  const renderAllCategoryInfo = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}admin/manage-site/home-page-setting/popular-category/category-list`,
        {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )
      .then((res) => {
        setALLCategoryList(res.data?.data);
      });
  };

  const [value, setValue] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [name, setName] = React.useState(null);

  // form submit to backend
  const categoryAddToPopular = (e) => {
    const formdata = new FormData();

    formdata.append("category_id", id);
    axios
      .post(
        `${BACKEND_BASE_URL}admin/manage-site/home-page-setting/popular-category/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setFile([]);
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response?.data?.errors?.category_id,
          });
        }
      });
    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}admin/manage-site/home-page-setting/popular-category/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("admin_access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
    renderAllCategoryInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <h5>Popular Categories</h5>
              </div>
            </div>
          </div>
        </div>

        {/* category make popular */}
        <div className="col-md-12 ps-4 pe-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <Form onSubmit={categoryAddToPopular}>
                  <div className="content-wrapper">
                    <div className="row py-1">
                      <div className="col-6 mb-2">
                        <div className="label fw-bold">
                          Make popular category
                        </div>

                        <Autocomplete
                          id="free-solo-demo"
                          options={aLLCategoryList}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          onChange={(event, newValue) => {
                            // console.log(newValue);
                            if (newValue) {
                              setValue(newValue);
                              setId(newValue.id);
                              setName(newValue.name);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                border: "1px solid #86b7fe",
                              },
                            },
                            "& .MuiInputBase-root": {
                              ".MuiInputBase-root:hover": {
                                border: "none",
                              },
                              padding: "0",
                            },
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          type="submit"
                          className="btn-submit mt-1 rounded-3 border-0"
                          style={{
                            width: "20%",
                          }}
                        >
                          <FiSave /> &nbsp; Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* table  */}
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>

                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-center">
                          Image
                        </th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.category?.name}</td>
                          <td className="text-center">
                            <img
                              className="img-thumbnail"
                              style={{
                                width: "80px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                              src={`${fileUrl}${data?.category?.image}`}
                              alt=""
                            />
                          </td>

                          <td className="text-center">
                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1.5em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularCategory;
