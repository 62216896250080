import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";

const UserProfileEdit = () => {
  const name = useRef();
  // const email = useRef();
  const phone = useRef();
  // const current_password = useRef();
  // const new_password = useRef();
  // const confirm_password = useRef();
  const address = useRef();
  const profile_photo = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Fetch all User Info
  // const [userInfo, setUserInfo] = useState();
  const [message, setMessage] = useState([]);

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
        setIsLoading(false);
        setError(false);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("name", name.current.value);
    formdata.append("phone", phone.current.value);
    // formdata.append("current_password", current_password.current.value);
    // formdata.append("new_password", new_password.current.value);
    // formdata.append("confirm_password", confirm_password.current.value);
    formdata.append("address", address.current.value);

    if (profile_photo.current.files[0]) {
      formdata.append("profile_photo", profile_photo.current.files[0]);
    }

    //user profile update api
    axios
      .post(`${BACKEND_BASE_URL}user/profile-update`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          renderUserInfo();
          // setModalShow(false);
        } else {
          setError(response.data?.errors);
          setMessage(response.data?.message);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    // <div className="user_dashboard_content p-4 p-lg-5">
    <div className="user_dashboard_content">
      {/* <h1 className="mb-5 ms-3 ms-lg-0">Edit Profile</h1> */}

      <div className="main__container">
        <div className="body-wrapper">
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <span className="top-border"></span>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Edit Profile</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isLoading && !error && profileInfo != null && (
            <div className="col-md-12 p-4">
              <div className="card">
                <div className="card-body">
                  <div className="col-lg-12">
                    <div className="edit_profile">
                      <Form autoComplete="off" onSubmit={storeData}>
                        <Form.Group className="user_dashboard_content mb-4">
                          <Form.Label className="">Username</Form.Label>
                          <Form.Control
                            className=""
                            autoComplete="off"
                            defaultValue={profileInfo?.name}
                            ref={name}
                          />
                        </Form.Group>

                        <Form.Group className="user_dashboard_content mb-4">
                          <Form.Label className="mb-2">Email</Form.Label>
                          <Form.Control
                            readOnly
                            className=""
                            type="email"
                            placeholder="Email"
                            // disabled
                            defaultValue={profileInfo?.email}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label className="mb-2">
                            Profile Photo
                          </Form.Label>
                          <Form.Control
                            type="file"
                            ref={profile_photo}
                            onChange={handleImgPreview}
                          />
                          {files.map((file, key) => {
                            return (
                              <div key={key} className="Row">
                                <span className="Filename">
                                  <img
                                    width={80}
                                    height={50}
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                  />
                                </span>
                              </div>
                            );
                          })}
                          {files.length === 0 &&
                          profileInfo != null &&
                          profileInfo?.profile_photo != null ? (
                            <img
                              width={80}
                              src={`${fileUrl}${profileInfo?.profile_photo}`}
                              alt={profileInfo.name}
                              className="img-thumbnail me-2 my-2"
                            />
                          ) : (
                            <img
                              src={require("../../../Assets/avatar.png")}
                              alt=""
                              className="img-fluid"
                              width={80}
                            />
                          )}
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label className="mb-2">
                            Address: <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            as="textarea"
                            type="textarea"
                            rows={3}
                            placeholder="Enter your address"
                            className=""
                            defaultValue={profileInfo?.address}
                            ref={address}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label className="mb-2">
                            Phone Number: <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            placeholder="Phone"
                            aria-label="Phone"
                            className=""
                            defaultValue={profileInfo?.phone}
                            ref={phone}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label className=" mb-2">
                            Country or region
                          </Form.Label>
                          <Form.Control
                            className=""
                            type="text"
                            placeholder="Bangladesh"
                            defaultValue="Bangladesh"
                          />
                        </Form.Group>

                        <div className="save_profile_btn mt-4 mb-5 flex_end">
                          {/* <button className="me-3">Cancel</button> */}
                          {/* <button onClick={() => {}}>Save Profile</button> */}
                          <button type="submit" className="me-3">
                            Save Profile
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isLoading && !error && (
            <>
              <div className="d-flex justify-content-center pb-5 pt-5">
                <DotLoader />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfileEdit;
