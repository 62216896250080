import { Navigate, useLocation } from "react-router-dom";
import getCookie from "../../../Component/Cookie/Get.Cookie";

export default function UserPrivateRoute({ children }) {
  // const user = sessionStorage.getItem("user_access_token");
  const user = getCookie("user_access_token");

  const location = useLocation();

  // return authUser.email ? children : <Navigate to="/user/login" replace state={{from: location}}/>;
  return user ? (
    children
  ) : (
    <Navigate to="/user/login" replace state={{ from: location }} />
  );
}
