import axios from "axios";
import React, { useRef, useState } from "react";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import Swal from "sweetalert2";
import "./serverUnderMaintenance.css";
import getCookie from "../../Component/Cookie/Get.Cookie";
import { BACKEND_BASE_URL } from "../../Utils/GlobalVariables";

import Cookie from "cookie-universal";

const ServerUnderMaintenance = () => {
  const secretVal = useRef();
  const [toast_bg, setToast_bg] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();

  const cookies = Cookie();

  // const ServerMainCheck = async () => {
  //   await axios
  //     .get(
  //       `${BACKEND_BASE_URL}/api/v2/server-maintenance-check/${
  //         secretVal.current.value
  //       }/${getCookie("VISITOR_UNIQUE_ID")}`
  //     )
  //     .then((res) => {
  //       if (res.data.status == 200) {
  //         // setCheckValue(res.data?.maintenance_data?.is_active);

  //         var now = new Date().getTime();
  //         sessionStorage.setItem("setupTime", now);
  //         sessionStorage.setItem(
  //           "VISITOR_UNIQUE_ID",
  //           res.data?.requestor_info?.visitor_unique_id
  //         );
  //         setToast_bg("success");
  //         setMessage(res.data?.message);
  //         setShow(true);
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           text: res.data.message,
  //           confirmButtonColor: "danger",
  //         });
  //       }
  //     });
  // };

  console.log("servePage", getCookie("VISITOR_UNIQUE_ID"));

  const handleKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      axios
        .get(
          `${BACKEND_BASE_URL}server-maintenance-check/${
            secretVal.current.value
          }/${getCookie("VISITOR_UNIQUE_ID")}`
        )
        .then((res) => {
          if (res?.data?.status == 200) {
            // var now = new Date().getTime();
            // document.cookie =
            //   "setupTime=" + now + "; max-age=" + 1 * 24 * 60 * 60;

            // ===========

            // document.cookie =
            //   "VISITOR_UNIQUE_ID=" +
            //   res.data?.requestor_info?.visitors_unique_id +
            //   "; max-age=" +
            //   1 * 24 * 60 * 60;

            // cookies.set(
            //   "VISITOR_UNIQUE_ID",
            //   res.data?.requestor_info?.visitors_unique_id,
            //   {
            //     maxAge: 60 * 60 * 24 * 7,
            //   }
            // );

            // setToast_bg("success");
            setToast_bg("info");
            setMessage(res.data?.message);
            setShow(true);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.message,
              confirmButtonColor: "danger",
            });
          }
        });
    }
  };

  return (
    <div className="under_maintenance vh-100">
      <div className="container position-relative">
        <div className="d-flex flex-column align-items-center ">
          <div className="text-center text-white">
            <ToastContainer
              position="top-center"
              className="p-3 toast_msg"
              style={{ zIndex: "999" }}
            >
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                bg={toast_bg}
                autohide
              >
                <Toast.Body>
                  <strong className="ms-3">{message ? message : ""}</strong>
                </Toast.Body>
              </Toast>
            </ToastContainer>

            <div className="image_icon d-flex justify-content-center">
              <img
                src={require("../../Assets/under maintenance/2.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <h1 className="mb-5 coming_soon">We'll be back soon!</h1>

            <h4 className="mb-5">
              Sorry for the inconvenience. <br /> We're performing some
              maintenance at the moment.
            </h4>

            <p className="mb-3">Thank you for your understanding.</p>

            <div>
              <Form.Group className="mb-2 password_field">
                <Form.Control
                  className="w-25 m-auto"
                  type="text"
                  placeholder="CODE"
                  ref={secretVal}
                  onKeyUp={handleKeyPress}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerUnderMaintenance;
