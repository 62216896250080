import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import RichTextEditor from "../../../Component/RichTextEditor";
import "./Keywords.css";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const CategoryEdit = () => {
  const { catId } = useParams();
  const name = useRef();
  const image = useRef();
  const desc = useRef();
  const keywords = useRef();

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial table data
  const [updateInfo, setUpdateInfo] = useState([]);
  //   const [updateMetaKeyInfo, setUpdateMetaKeyInfo] = useState([]);
  // Get Single Info
  const renderUpdateInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/categories/${catId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setUpdateInfo(res.data?.single_info);
        setTags(res.data?.single_info_keywords?.[0].split(","));
      });
  };

  // description
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  //keyword multi
  const [tags, setTags] = useState([]);
  const TagInput = ({ limit, tags, setTags }) => {
    const [newTag, setNewTag] = React.useState("");
    const handleChange = (e) => {
      if (e.target.value !== ",") {
        setNewTag(e.target.value);
      }
    };

    const handleKeyDown = (e) => {
      if (e.keyCode == 13) {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    };
    const handleDelete = (e) => {
      let indexToDelete = parseInt(e.target.id);
      setTags([
        ...tags.slice(0, indexToDelete),
        ...tags.slice(indexToDelete + 1),
      ]);
    };
    return (
      <div className="TagInput">
        <div className="Tags">
          {tags.map((tag, index) => (
            <div className="Tag" key={index}>
              <span>{tag}</span>
              <button className="Delete" onClick={handleDelete} id={index}>
                ✕
              </button>
            </div>
          ))}
          <input
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={newTag}
            disabled={tags.length == limit ? true : false}
            placeholder="Separate keywords by pressing Enter"
            ref={keywords}
          />
        </div>
      </div>
    );
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    // formdata.append("_method", "PUT");
    formdata.append("_method", "PATCH");

    formdata.append("name", name.current.value);

    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }

    formdata.append("meta_description", desc.current.value);

    formdata.append("meta_keywords[]", tags);

    axios
      .post(`${BACKEND_BASE_URL}admin/categories/update/${catId}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response.data?.status === "ok") {
          ToastSuccess.fire({
            icon: "success",
            // title: response.data?.message,
            title: "Category update successfull...!",
          });
          e.target.reset();
          setFile([]);
          //   setValue("", "html");
          setTags([]);
          renderUpdateInfo();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderUpdateInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Update Category Info</div>
                  <Link to="/admin/category">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={updatedData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add name */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Name&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="name"
                              ref={name}
                              defaultValue={updateInfo?.name}
                            />
                          </Form.Group>

                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />
                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                            {files.length == 0 &&
                              (updateInfo?.image != null ? (
                                <img
                                  className="img-thumbnail mt-1"
                                  width={80}
                                  height={50}
                                  src={`${fileUrl}${updateInfo?.image}`}
                                  alt=""
                                  name="img"
                                />
                              ) : (
                                ""
                              ))}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Meta Description&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              ref={desc}
                              defaultValue={updateInfo?.meta_description}
                            />
                            {/* <RichTextEditor
                              joditRef={desc}
                              getValue={getValue}
                              value={updateInfo?.meta_description}
                            /> */}
                          </Form.Group>

                          {/* <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3">
                            <Form.Label className="label fw-bold">
                              Description &nbsp;{" "}
                            </Form.Label>

                            <RichTextEditor
                              joditRef={desc}
                              getValue={getValue}
                              value={updateInfo?.meta_description}
                            />
                          </Form.Group> */}

                          {/* Keywords */}
                          <div className="col-12">
                            <p className="label fw-bold">
                              Meta Kewords&nbsp;{""}
                              <span className="text-danger">*</span>
                            </p>
                            <TagInput
                              limit={20}
                              tags={tags}
                              setTags={setTags}
                            />
                          </div>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategoryEdit;
