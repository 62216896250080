import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";
import Cookie from "cookie-universal";
import { ToastSuccess } from "../../../Component/ToastAlert";
import Swal from "sweetalert2";

const UserCart = () => {
  const cookies = Cookie();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Initial cart count data
  const [cart_count, setCartCount] = useState();
  const [cart_items, setCartItems] = useState();
  // Get Cart Count Info
  const renderCartInfo = async () => {
    let userId;
    userId = getCookie("user_id");
    await axios
      .get(`${BACKEND_BASE_URL}user/cart/view-items/${userId}`, {
        headers: {
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setCartItems(res?.data?.data);
        setCartCount(res?.data?.cart_count);
        setIsLoading(false);
        setError(false);
        if (res.data.cart_count) {
          cookies.set("cart_count", res.data.cart_count, {
            maxAge: 60 * 60 * 24 * 7,
          });
        }
      });
  };

  const qtyUpdatePlus = async (id) => {
    await axios
      .put(
        `${BACKEND_BASE_URL}user/cart/update-items-qty/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
        }
        renderCartInfo();
      });
  };

  const qtyUpdateMinus = async (id) => {
    await axios
      .put(
        `${BACKEND_BASE_URL}user/cart/update-items-qty-minus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
        } else if (response.data?.status == 201) {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        }
        renderCartInfo();
      });
  };

  const renderCartRemoveItems = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      return result.isConfirmed;
    });
    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}user/cart/remove-items/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            cookies.set("cart_count", response.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderCartInfo();
          }
        });
    }
  };

  useEffect(() => {
    renderCartInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Cart Items</h5>
                  {/* <Link to="/user/ticket/new">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Add New &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* jodi data pay */}
        {!isLoading && !error && cart_items?.length > 0 && (
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 cart">
                  <div className="cart_table mb-2">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th>PRODUCT NAME</th>
                          {/* <th>SKU</th> */}
                          <th>UNIT PRICE</th>
                          <th>QUANTITY</th>
                          <th>TOTAL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart_items?.map((data, i) => (
                          <tr key={data.id}>
                            <td className="table_image">
                              <img
                                className="img-fluid"
                                src={`${fileUrl}${data?.product?.featured_image}`}
                                alt=""
                              />
                            </td>
                            <td>
                              <h5 className="font_size_14 fw_700 ">
                                {data.product.name}
                              </h5>
                            </td>
                            {/* <td className="font_size_14 fw_600 ">
                              {data.product.sku}
                            </td> */}
                            <td className="font_size_14 fw_600 ">
                              {data.product.price_including_tax > 0 ? (
                                <>
                                  ${data?.product.price_including_tax}{" "}
                                  <span className="font_size_16 fw_200">
                                    (including vat)
                                  </span>
                                </>
                              ) : (
                                <>${data?.product.current_price} </>
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-between  counter">
                                <button
                                  className="border-0"
                                  onClick={() => qtyUpdateMinus(data.id)}
                                >
                                  -
                                </button>
                                <input type="text" value={data.qty} />
                                <button
                                  className="border-0"
                                  onClick={() => qtyUpdatePlus(data.id)}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="font_size_14 fw_600 ">
                              {data.product.price_including_tax > 0 ? (
                                <>
                                  $
                                  {data?.product.price_including_tax * data.qty}
                                </>
                              ) : (
                                <>${data?.product.current_price * data.qty} </>
                              )}
                            </td>
                            <td className="font_size_14 fw_600 cl_lightGrey ">
                              <AiOutlineCloseCircle
                                size={30}
                                className="pointer"
                                onClick={() => renderCartRemoveItems(data.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="checkout_button d-flex justify-content-end">
                      <Link to="/checkout" className="underline_none">
                        <button className="ms-auto">Checkout</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading && !error && (
          <>
            <div className="d-flex justify-content-center pb-5 pt-5">
              <DotLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default UserCart;
