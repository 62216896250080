// import { CircularProgress } from "@mui/material";
import axios from "axios";
import "./userProfileDashboard.css";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";

const UserProfileDashboard = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
        setIsLoading(false);
        setError(false);
      });
  };
  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    <div className="user_dashboard_content">
      {/* <div className="user_dashboard_content p-4 p-lg-5"> */}
      {/* <h1 className="mb-5 ms-3 ms-lg-0">Account Overview</h1> */}
      {/* <h3 className="mb-4">Profile</h3> */}

      <div className="main__container">
        <div className="body-wrapper">
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <span className="top-border"></span>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Account Overview</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {!isLoading && !error && profileInfo != null && (
              <>
                <div className="col-md-12 p-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-lg-12">
                        <Form.Group className="d-flex align-items-center form_group">
                          <Form.Label className="w-50 mb-0">
                            Username
                          </Form.Label>
                          <Form.Control
                            readOnly
                            className="w-50"
                            type="text"
                            placeholder="Username"
                            defaultValue={profileInfo?.name}
                          />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center form_group">
                          <Form.Label className="w-50 mb-0">Email</Form.Label>
                          <Form.Control
                            readOnly
                            className="w-50"
                            type="text"
                            placeholder="Email"
                            defaultValue={profileInfo?.email}
                          />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center form_group">
                          <Form.Label className="w-50 mb-0">
                            Profile Photo
                          </Form.Label>
                          <div>
                            {files.length === 0 &&
                            profileInfo != null &&
                            profileInfo?.profile_photo != null ? (
                              <img
                                src={`${fileUrl}${profileInfo?.profile_photo}`}
                                alt="Profile"
                                className="img-fluid"
                                width={80}
                              />
                            ) : (
                              <img
                                src={require("../../../Assets/avatar.png")}
                                alt=""
                                className="img-fluid"
                                width={80}
                              />
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center form_group">
                          <Form.Label className="w-50 mb-0">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            readOnly
                            className="w-50"
                            type="text"
                            placeholder="phone"
                            defaultValue={profileInfo?.phone}
                          />
                        </Form.Group>

                        <div className="edit_profile_btn mt-4 mb-5">
                          <button>
                            <Link to="edit-profile">Edit Profile</Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {isLoading && !error && (
            <>
              <div className="d-flex justify-content-center pb-5 pt-5">
                <DotLoader />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfileDashboard;
