/*===================
    Product details  INDEX  
=====================
1. variable 
2. slider setting arrow design

  a. right arrow design
  b. left arrow design

3. main function home()

  a. slider setting
  b. dynamically title and meta tag for seo
  c. header 
  d. homeProductDetails
  e. footer 

================*/

// variable

import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import Footer from "../../Component/Common/Footer/Footer";
import Header from "../../Component/Common/Header/Header";
// import "./HomeProductDetails.css";
import { Link, Navigate, useParams } from "react-router-dom";

import product_test2 from "../../Assets/test.jpg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import Slider from "react-slick";
import RelatedProduct from "../../Component/Common/RelatedProduct/RelatedProduct";
import { Helmet } from "react-helmet";
import { USerContext } from "../../App";
import { BACKEND_BASE_URL, fileUrl } from "../../Utils/GlobalVariables";
import axios from "axios";
import { ToastSuccess } from "../../Component/ToastAlert";
import getCookie from "../../Component/Cookie/Get.Cookie";
import Cookie from "cookie-universal";
import { DotLoader } from "../../Component/Custom Loader/CustomLoader";
import Parse from "html-react-parser";

// main function
const BlogDetails = () => {
  const { blogId } = useParams();
  //set single data
  const [singleData, setSingleData] = useState(null);
  // Get All graphics Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}blogs/show-single/${blogId}`)
      .then((res) => {
        setSingleData(res?.data?.data);
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <>
      {/* header */}
      <Header />
      {/*======== dynamically title and meta tag for seo start ========*/}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fantabulous | Blog Details</title>
      </Helmet>
      <div className="productDetails sesction_mt my-5">
        <div className="custom_container container_fluid_md product_thumb">
          <Row className="">
            {/* left side product thumb & overview */}
            {singleData != null ? (
              <Col md={12} className="mb-4">
                <Row className="main_image sesction_mb px-3">
                  <div
                    className="border-0 rounded-0 w-100 single_team"
                    key={singleData?.id}
                  >
                    <div className="team_img ">
                      <img
                        className="w-100 border-0 rounded-1 mb-3"
                        src={`${fileUrl}${singleData?.image}`}
                        alt=""
                      />
                    </div>
                  </div>
                  <h2 className="" style={{ fontFamily: "sans-serif" }}>
                    {singleData?.title}
                  </h2>
                  <div
                    className="mt-4"
                    style={{ fontSize: "18px", fontFamily: "sans-serif" }}
                  >
                    {Parse(`${singleData?.details}`)}
                  </div>
                </Row>
              </Col>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <DotLoader />
              </div>
            )}
          </Row>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  );
};

export default BlogDetails;
