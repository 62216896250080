import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import { MdDeleteForever } from "react-icons/md";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { FaEye } from "react-icons/fa";

const DeliveredOrders = () => {
  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/orders/delivered`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };

  //status update
  const statusUpdate = (e, id) => {
    const formdata = new FormData();
    e.preventDefault();
    formdata.append("_method", "PATCH");

    formdata.append("order_status", e.target.value);
    axios
      .post(`${BACKEND_BASE_URL}admin/orders/status-update/${id}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })

      .then((response) => {
        if (response?.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response?.data?.message,
          });
        }
      });
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/orders/remove/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                {/* <h5>FAQ Categories</h5> */}
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Delivered orders</h5>
                  {/* <Link to="/admin/faq">
                    <Button variant="success" size="sm">
                      View All FAQ Contents &nbsp;
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>

                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.orderId}</td>
                          <td>$ {data?.total_amount}</td>
                          <td>
                            <div className="">
                              <Form>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <option
                                    value="pending"
                                    selected={
                                      data?.payment_status == "pending"
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    Unpaid
                                  </option>

                                  <option
                                    value="paid"
                                    selected={
                                      data?.payment_status == "paid"
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    Paid
                                  </option>
                                </Form.Select>
                              </Form>
                            </div>
                          </td>

                          {/* order status update */}
                          <td>
                            <div className="">
                              {/* <Form> */}
                              <Form.Select
                                aria-label="Default select example"
                                style={{
                                  width: "50%",
                                }}
                                onChange={(e) => statusUpdate(e, data.id)}
                              >
                                <option
                                  value="1"
                                  selected={
                                    data?.order_status == 1 ? "selected" : ""
                                  }
                                >
                                  Pending
                                </option>

                                <option
                                  value="2"
                                  selected={
                                    data?.order_status == 2 ? "selected" : ""
                                  }
                                >
                                  In Progress
                                </option>
                                <option
                                  value="3"
                                  selected={
                                    data?.order_status == 3 ? "selected" : ""
                                  }
                                >
                                  Delivered
                                </option>
                                <option
                                  value="4"
                                  selected={
                                    data?.order_status == 4 ? "selected" : ""
                                  }
                                >
                                  Cancelled
                                </option>
                              </Form.Select>
                              {/* </Form> */}
                            </div>
                          </td>

                          {/* Actions  */}
                          <td>
                            {/* view button */}
                            <Link to={`/admin/orders/invoice/${data?.id}`}>
                              <button className="py-1 px-2 bg-info border-0 rounded-2 me-1 mb-1">
                                <FaEye
                                  style={{
                                    color: "white",
                                  }}
                                  title="invoice"
                                  size="1em"
                                />
                              </button>
                            </Link>

                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-2 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveredOrders;
