import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiBullseye, BiEdit, BiHappyHeartEyes } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { FaEye } from "react-icons/fa";
import moment from "moment/moment";

const SingleOrder = () => {
  const { orderId } = useParams();

  // Initial table data
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  // Get Single Info
  const renderInvoiceInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/orders/show-single/${orderId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setInvoiceInfo(res?.data?.data);
      });
  };

  const Print = () => {
    // let printContents = document.getElementById("printablediv").innerHTML;
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  useEffect(() => {
    renderInvoiceInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                {/* <h5>FAQ Categories</h5> */}
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Order info</h5>
                  <Link to="/admin/orders/type=all">
                    <Button variant="success" size="sm">
                      View All Orders&nbsp;
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4 " id="printablediv">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <h5>Oder Details:</h5>
                    <p className="m-0">Order Id: {invoiceInfo?.orderId} </p>
                    <p className="m-0">
                      Order Date:{" "}
                      {moment(invoiceInfo.created_at).format("Do MMMM YYYY")}{" "}
                    </p>

                    {invoiceInfo?.order_status == 1 ? (
                      <p className="m-0">Order Status: Pending</p>
                    ) : invoiceInfo?.order_status == 2 ? (
                      <p className="m-0">Order Status: In progress</p>
                    ) : invoiceInfo?.order_status == 3 ? (
                      <p className="m-0">Order Status: Delivered</p>
                    ) : invoiceInfo?.order_status == 4 ? (
                      <p className="m-0">Order Status: Cancelled</p>
                    ) : (
                      ""
                    )}

                    {/* <p className="m-0">
                      Order Status: {invoiceInfo?.order_status}{" "}
                    </p> */}

                    <p className="m-0">
                      Payment Method: {invoiceInfo?.payment_type}{" "}
                    </p>
                    <p className="mb-3">
                      Payment Status: {invoiceInfo?.payment_status}{" "}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h5>Shipping Address:</h5>
                    <p className="m-0">Name: {invoiceInfo?.name} </p>
                    <p className="m-0">Phone: {invoiceInfo?.phone} </p>
                    <p className="m-0">Email: {invoiceInfo?.email} </p>
                    <p className="m-0">Address: {invoiceInfo?.address} </p>
                    <p className="m-0">City: {invoiceInfo?.city} </p>
                    <p className="m-0">Zip code: {invoiceInfo?.zip} </p>
                  </div>
                </div>

                <div className="table-responsive py-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Products</th>
                        <th scope="col">Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceInfo?.order_details?.map((data, i) => (
                        <tr key={data.id}>
                          <td>{data.products.name}</td>
                          <td>
                            {data.price_including_tax > 0 ? (
                              <>
                                $ {data?.price_including_tax}{" "}
                                <span className="font_size_16 fw_200">
                                  (including vat)
                                </span>
                              </>
                            ) : (
                              <>$ {data?.product_price} </>
                            )}
                          </td>
                          <td>{data.quantity}</td>
                          <td>
                            {data.price_including_tax > 0 ? (
                              <>
                                $ {data?.price_including_tax * data.quantity}{" "}
                                <span className="font_size_16 fw_200">
                                  (including vat)
                                </span>
                              </>
                            ) : (
                              <>$ {data?.product_price * data.quantity} </>
                            )}
                          </td>
                        </tr>
                      ))}
                      {invoiceInfo?.discount_amount != null && (
                        <tr>
                          <td></td>
                          <td></td>
                          <th>Discount:</th>
                          <th>$ {invoiceInfo?.discount_amount}</th>
                        </tr>
                      )}
                      <tr>
                        <td></td>
                        <td></td>
                        <th>Total amount:</th>
                        <th>$ {invoiceInfo?.total_amount}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>

          <div className="col-md-12 px-4 d-flex justify-content-end">
            <Button variant="success" size="xl" onClick={Print}>
              Print&nbsp;
            </Button>
          </div>
        
      </div>
    </div>
  );
};

export default SingleOrder;
