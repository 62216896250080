/*===================
        Header
=====================
1. variable 
2. main function

  a. custom function for nav click nav close
  b. top_header
  b. main_header
    i.Logo 
    ii. Nav Menu
    iii. Nav Icons

================*/

import React, { useContext, useEffect, useState } from "react";
// import {
//   Container,
//   Nav,
//   Navbar,
//   NavDropdown,
//   Offcanvas,
// } from "react-bootstrap";
import "./Header.css";
import logo_long from "../../../Assets/Home/logo_long.png";
import logo_short from "../../../Assets/Home/logo_short.png";

import { FaSearch } from "react-icons/fa";
import { BiShoppingBag, BiUser } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { USerContext } from "../../../App";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import getCookie from "../../Cookie/Get.Cookie";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import Cookie from "cookie-universal";

const Header = () => {
  const navigate = useNavigate();
  const { cart, wishlist } = useContext(USerContext);

  const cookies = Cookie();

  // Get profile Info
  const renderProfileInfo = async () => {
    if (getCookie("user_access_token")) {
      navigate("/user");
    } else if (getCookie("admin_access_token")) {
      navigate("/admin");
    } else {
      navigate("/user/login");
    }
  };

  // Initial cart count data
  const [cart_count, setCartCount] = useState();
  // Get Cart Count Info
  const renderCartInfo = async () => {
    if (getCookie("user_id")) {
      await axios
        .get(
          `${BACKEND_BASE_URL}user/cart/view-items/${getCookie("user_id")}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("user_access_token")}`,
            },
          }
        )
        .then((res) => {
          setCartCount(res?.data?.cart_count);
          if (res.data.cart_count) {
            cookies.set("cart_count", res.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
          }
        });
    } else {
      await axios
        .get(
          `${BACKEND_BASE_URL}user/cart/view-items/${getCookie(
            "VISITOR_UNIQUE_ID"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("user_access_token")}`,
            },
          }
        )
        .then((res) => {
          setCartCount(res?.data?.cart_count);
          if (res.data.cart_count) {
            cookies.set("cart_count", res.data.cart_count, {
              maxAge: 60 * 60 * 24 * 7,
            });
          }
        });
    }
  };

  // custom function for nav click nav close
  const navClose = () => {
    document.querySelector(".btn-close").click();
  };

  useEffect(() => {
    // renderProfileInfo();
    renderCartInfo();
  }, [getCookie("cart_count")]);
  return (
    <>
      {/*======== top_header start ========*/}
      <div className="top_header py-2 text-center bg_lightDark cl_white font_size_12 ls_002 text-uppercase">
        Worldwide shipping - free returns
      </div>
      {/*======== top header end ========*/}

      {/*======== main_header start ========*/}
      <Navbar
        expand="lg"
        className="main_header bg_lightDark px-3"
        variant="dark"
        sticky="top"
      >
        <Container fluid>
          {/*====== Logo ======*/}
          <Navbar.Brand as={Link} to="/" className="cl_white logo">
            <picture>
              <source media="(min-width:1350px)" srcSet={logo_long} />
              <img src={logo_short} alt="mySvgImage" />
            </picture>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
          >
            {/*====== Nav Menu ======*/}
            <Offcanvas.Header className="" closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center align-items-md-center flex-grow-1 ">
                <NavDropdown
                  title="Home"
                  id="offcanvasNavbarDropdown-expand-lg"
                >
                  <NavDropdown.Item as={Link} to="/" onClick={navClose}>
                    Home1
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/home2" onClick={navClose}>
                    Home2
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Pages"
                  id="offcanvasNavbarDropdown-expand-lg"
                >
                  <NavDropdown.Item as={Link} to="/login" onClick={navClose}>
                    Login
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/new-user" onClick={navClose}>
                    User Registration
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/forget-password"
                    onClick={navClose}
                  >
                    Forget Password
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/about-us" onClick={navClose}>
                    About Us
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/product" onClick={navClose}>
                  Products
                </Nav.Link>

                <Nav.Link as={Link} to="/" onClick={navClose}>
                  Blog
                </Nav.Link>

                <NavDropdown
                  title="Shop"
                  id="offcanvasNavbarDropdown-expand-lg"
                >
                  <NavDropdown.Item as={Link} to="/wishlist" onClick={navClose}>
                    Wishlist
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cart" onClick={navClose}>
                    Cart
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/checkout" onClick={navClose}>
                    Checkout
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/" onClick={navClose}>
                  Elements
                </Nav.Link>
              </Nav>
              {/*====== Nav Icons =======*/}
              <div className="icon_box d-flex align-items-center transition_all_3_ease">
                <div className="search me-3">
                  <FaSearch size={24} />
                </div>
                <div className="user me-3" onClick={renderProfileInfo}>
                  <Link
                    to=""
                    className="underline_none cl_medium_grey_header1 fw_500"
                  >
                    <BiUser
                      className="me-2 cl_medium_grey_header1 cl_white"
                      size={24}
                    />
                  </Link>

                  {/* <Link to="/login" className="cl_white">
                    <BiUser size={24} />
                  </Link> */}
                </div>
                <div className="user me-4">
                  <Link to="/wishlist" className="cl_white">
                    <div className=" position-relative">
                      <AiOutlineHeart className="" size={24} />
                      <span className="position-absolute top-0 start-100 translate_bottom p-2 cl_gold">
                        {wishlist}
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="cart">
                  <Link to="/cart" className="cl_white">
                    <div className=" position-relative">
                      <BiShoppingBag className="" size={24} />
                      <span className="position-absolute top-0 start-100 translate_bottom p-2 cl_gold">
                        {cart_count}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/*======== main_nav end ========*/}
    </>
  );
};

export default Header;
