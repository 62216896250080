import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Cookie from "cookie-universal";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";
import moment from "moment/moment";

const UserSingleOrder = () => {
  const { orderId } = useParams();
  const cookies = Cookie();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Initial table data
  const [singleOrderInfo, setSingleOrderInfo] = useState([]);
  // Get Single Info
  const renderSingleOrderInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/orders/show-single/${orderId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setSingleOrderInfo(res?.data?.data);
        setIsLoading(false);
        setError(false);
      });
  };
  useEffect(() => {
    renderSingleOrderInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>My Orders</h5>
                  <Link to="/user/orders">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Back
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* jodi data pay */}
        {!isLoading && !error && singleOrderInfo != null && (
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5>Oder Details:</h5>
                      <p className="m-0">
                        Order Id: {singleOrderInfo?.orderId}{" "}
                      </p>
                      <p className="m-0">
                        Order Date:{" "}
                        {moment(singleOrderInfo.created_at).format(
                          "Do MMMM YYYY"
                        )}{" "}
                      </p>

                      {singleOrderInfo?.order_status == 1 ? (
                        <p className="m-0">Order Status: Pending</p>
                      ) : singleOrderInfo?.order_status == 2 ? (
                        <p className="m-0">Order Status: In progress</p>
                      ) : singleOrderInfo?.order_status == 3 ? (
                        <p className="m-0">Order Status: Delivered</p>
                      ) : singleOrderInfo?.order_status == 4 ? (
                        <p className="m-0">Order Status: Cancelled</p>
                      ) : (
                        ""
                      )}

                      <p className="m-0">
                        Payment Method: {singleOrderInfo?.payment_type}{" "}
                      </p>
                      <p className="mb-3">
                        Payment Status: {singleOrderInfo?.payment_status}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <h5>Shipping Address:</h5>
                      <p className="m-0">Name: {singleOrderInfo?.name} </p>
                      <p className="m-0">Phone: {singleOrderInfo?.phone} </p>
                      <p className="m-0">Email: {singleOrderInfo?.email} </p>
                      <p className="m-0">
                        Address: {singleOrderInfo?.address}{" "}
                      </p>
                      <p className="m-0">City: {singleOrderInfo?.city} </p>
                      <p className="m-0">Zip code: {singleOrderInfo?.zip} </p>
                    </div>
                  </div>

                  <div className="table-responsive py-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Products</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleOrderInfo?.order_details?.map((data, i) => (
                          <tr key={data.id}>
                            <td>{data.products.name}</td>
                            <td>
                              {data.price_including_tax > 0 ? (
                                <>
                                  $ {data?.price_including_tax}{" "}
                                  <span className="font_size_16 fw_200">
                                    (including vat)
                                  </span>
                                </>
                              ) : (
                                <>$ {data?.product_price} </>
                              )}
                            </td>
                            <td>{data.quantity}</td>
                            <td>
                              {data.price_including_tax > 0 ? (
                                <>
                                  $ {data?.price_including_tax * data.quantity}{" "}
                                  <span className="font_size_16 fw_200">
                                    (including vat)
                                  </span>
                                </>
                              ) : (
                                <>$ {data?.product_price * data.quantity} </>
                              )}
                            </td>
                          </tr>
                        ))}
                        {singleOrderInfo?.discount_amount != null && (
                          <tr>
                            <td></td>
                            <td></td>
                            <th>Discount:</th>
                            <th>$ {singleOrderInfo?.discount_amount}</th>
                          </tr>
                        )}
                        <tr>
                          <td></td>
                          <td></td>
                          <th>Total amount:</th>
                          <th>$ {singleOrderInfo?.total_amount}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading && !error && (
          <>
            <div className="d-flex justify-content-center pb-5 pt-5">
              <DotLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserSingleOrder;
