import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { Button, Col, Form } from "react-bootstrap";

const Maintenance = () => {
  const secret_code = useRef();
  const [maintainanceInfo, setMaintainanceInfo] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/manage-site/maintenance-setting`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setMaintainanceInfo(res.data?.data);
      });
  };

  // form submit to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PATCH");

    formdata.append("secret_code", secret_code.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}admin/manage-site/maintenance-setting/update/${1}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data.message,
          });
          renderAllInfo();
        }
      });
    e.preventDefault();
  };

  // Delete Data
  const LiveNow = async (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}admin/manage-site/maintenance-setting/off/${1}`,
        {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          renderAllInfo();
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        }
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Maintainance</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                {maintainanceInfo?.status == 0 && (
                  <Form onSubmit={updatedData}>
                    <div className="text-center">
                      <div className="row">
                        <h6>Site is in Live mode</h6>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <Form.Group
                          as={Col}
                          md="6"
                          lg="4"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Maintainance Mode Secret Key&nbsp;{""}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            ref={secret_code}
                            defaultValue={maintainanceInfo?.secret_code}
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Button
                          type="submit"
                          className="btn-submit mt-2 rounded-2 border-0"
                          style={{ width: "100px" }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}

                {maintainanceInfo?.status == 1 && (
                  <div>
                    <div className="row text-center">
                      <h6>Site is in Maintainance mode</h6>
                    </div>
                    <div className="row">
                      <Button
                        onClick={LiveNow}
                        className="btn-submit bg-info btn-sm mt-2 rounded-2 border-0"
                        style={{ width: "100px" }}
                      >
                        Live now
                      </Button>
                    </div>
                  </div>
                )}

                {/* <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Email</th>

                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.email}</td>
                          <td className="text-center">
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
