import React from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { BACKEND_BASE_URL, fileUrl } from "../Utils/GlobalVariables";
import axios from "axios";
import Swal from "sweetalert2";
// import getCookie from "../../../Component/Cookie/Get.Cookie";
import "./admindashboard.css";

const AdminDashboardNavbar = (props) => {
  const [show, setShow] = useState(false);

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial table data
  const [profileInfo, setProfileInfo] = useState([]);
  // Get All Info
  //   const renderAllInfo = async () => {
  //     await axios
  //       .get(`${BACKEND_BASE_URL}admin/profile`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("hsl_token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         setProfileInfo(res.data?.data);
  //       });
  //   };
  //   const navigate = useNavigate();
  //   const logoutAdmin = () => {
  //     axios
  //       .get(`${BACKEND_BASE_URL}admin/logout`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("hsl_token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         localStorage.removeItem("hsl_token");
  //         Swal.fire({
  //           icon: "success",
  //           text: res.data.message,
  //           confirmButtonColor: "#5eba86",
  //         });
  //         navigate("/admin/login");
  //         // }
  //       });
  //   };
  useEffect(() => {
    // renderAllInfo();
    // renderAddInfo();
  }, []);

  return (
    <div className="topbar">
      <div className="row gx-0 justify-content-end searchbox ">
        <div className="col-md-10 col-xl-6">
          <div className="row gx-0 d-flex justify-content-end align-items-center">
            {/* <div className="col-md-7 col-xl-8">
              <Form>
                <Form.Group className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search for projects and items"
                    className="form_control"
                  />
                  <BsIcons.BsSearch size="1.2em" className="search_icon" />
                </Form.Group>
              </Form>
            </div> */}
            <div className="col-md-5 col-xl-4 flex_around">
              {/* <AiIcons.AiOutlineInfoCircle size="1.5em" />
              <BsIcons.BsGift size="1.5em" /> */}

              <NavDropdown
                onClick={() => setShow(!show)}
                className="admin_profile_dropdown position-relative"
                id="collasible-nav-dropdown"
                title={
                  <div className={`admin_profile_logo`}>
                    {files.length == 0 && (
                      <img
                        width={50}
                        // src={`${fileUrl}${profileInfo?.profile_photo}`}
                        alt="Profile picture"
                        name="img"
                        className="img-fluid"
                      />
                    )}
                  </div>
                }>
                {/* <NavDropdown.Item as={Link} to="#" className=" border-bottom">
                  <div className="flex_start">
                    <div className="mb-3">
                      <h4 className="mb-0 cl-333">
                        {localStorage.getItem("LOGGED_IN_USER_NAME")}
                      </h4>
                      <p className="mb-0">
                        {localStorage.getItem("LOGGED_IN_USER_EMAIL")}
                      </p>
                    </div>
                  </div>
                </NavDropdown.Item> */}

                {/* <div className="arrow-up"></div> */}

                <NavDropdown.Item
                  as={Link}
                  to="/admin/profile"
                  className=" t-fs-18 cl-333">
                  Profile
                </NavDropdown.Item>
                <hr className="m-0" />
                {/* <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Personal Settings
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  as={Link}
                  //   onClick={logoutAdmin}
                  to="/admin"
                  className="t-fs-18 cl-333">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNavbar;
