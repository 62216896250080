import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import "./userProfileDashboard.css";
import {
  AiFillHome,
  AiOutlineDelete,
  AiOutlineMenu,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import { HiLockClosed, HiOutlineDocumentText } from "react-icons/hi";

import "./userProfileDashboard.css";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import axios from "axios";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import Cookie from "cookie-universal";
import { FiLogOut, FiShoppingBag } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import Swal from "sweetalert2";

const UserProfile2 = () => {
  document.title = "User Panel";
  const [toggleNav, setToggleNav] = useState(false);
  const cookies = Cookie();

  // Image Preview
  // const [files, setFile] = useState([]);
  // const handleImgPreview = (e) => {
  //   let allfiles = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     allfiles.push(e.target.files[i]);
  //   }
  //   if (allfiles.length > 0) {
  //     setFile(allfiles);
  //   }
  // };

  const [profileInfo, setProfileInfo] = useState([]);
  // Get user Info
  const renderUserInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setProfileInfo(res.data?.data);
      });
  };

  const navigate = useNavigate();
  const logoutUser = () => {
    axios
      .get(`${BACKEND_BASE_URL}user/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        cookies.removeAll();
        ToastSuccess.fire({
          icon: "success",
          title: res.data?.message,
        });
        navigate("/user/login");
      });
  };

  // Delete Data
  const deleteAccount = async () => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete my account!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}user/deleteAccount`, {
          headers: {
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            cookies.removeAll();
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            navigate("/user/login");
          }
        });
    }
  };

  useEffect(() => {
    renderUserInfo();
  }, []);

  return (
    // <div style={{ backgroundColor: "#1c273b" }}>
    <div style={{ backgroundColor: "rgb(243, 245, 246)" }}>
      <div className="user_profile_navbar ">
        <Navbar expand="lg" className="custom_container">
          <Navbar.Brand href="/">
            <img
              src={require("../../../Assets/Common/12.png")}
              alt=""
              className="img-fluid ms-2"
              width={70}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-white">
              <Nav.Link href="/user/cart" className="me-3">
                Cart
              </Nav.Link>
              {/* <Nav.Link href="/user/orders" className="me-3"> */}
              <Nav.Link href="/user/orders" className="me-3">
                <span>My Orders</span>
              </Nav.Link>
              <div className="user_profile_text logo_img_wrapper me-3">
                {profileInfo != null && profileInfo?.profile_photo != null ? (
                  <img
                    src={`${fileUrl}${profileInfo?.profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={require("../../../Assets/avatar.png")}
                    alt=""
                    className="w-100 h-100"
                  />
                )}
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="bg-transparent border-0"
                >
                  Profile
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/user/favorites"> */}
                  <Dropdown.Item href="/user/">Profile</Dropdown.Item>
                  <Dropdown.Item href="/user/cart">Cart</Dropdown.Item>
                  <Dropdown.Item href="/user/orders">My Orders</Dropdown.Item>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Dropdown.Item href="" onClick={logoutUser}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div className="custom_container">
        <div className="d-flex user_profile_dashboard">
          <nav className={`user-profile-sidebar ${toggleNav ? "close" : ""}`}>
            <header className="my-5">
              <Link to="/" target="_blank" className="text-decoration-none">
                <div className="mx-4 d-flex justify-content-center align-items-center">
                  <div className="user_profile_text logo_img_wrapper">
                    {profileInfo != null &&
                    profileInfo?.profile_photo != null ? (
                      <img
                        src={`${fileUrl}${profileInfo?.profile_photo}`}
                        alt="Profile"
                        className="img-fluid"
                        // className="w-100 h-100 img-fluid"
                      />
                    ) : (
                      <img
                        src={require("../../../Assets/avatar.png")}
                        alt=""
                        className="w-100 h-100"
                      />
                    )}
                  </div>
                </div>
                <div className="flex_center mb-4"></div>
              </Link>

              <AiOutlineMenu
                className="bx bx-chevron-right toggle d-lg-none"
                onClick={() => setToggleNav(!toggleNav)}
              />
            </header>

            <div className="menu-bar">
              <div className="menu">
                <ul className="menu-links ps-0">
                  <NavLink to="/user/" title="Dashboard">
                    <li className={`nav-link`}>
                      <MdDashboard className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Dashboard
                      </span>
                    </li>
                  </NavLink>
                  <NavLink to="orders" title="Orders">
                    <li className={`nav-link`}>
                      <FiShoppingBag className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Orders
                      </span>
                    </li>
                  </NavLink>
                  <NavLink to="cart" title="Cart">
                    <li className={`nav-link`}>
                      <AiOutlineShoppingCart className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Cart
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="ticket" title="Support Ticket">
                    <li className={`nav-link`}>
                      <HiOutlineDocumentText className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Support Ticket
                      </span>
                    </li>
                  </NavLink>

                  {/* <NavLink to="/user/"> */}
                  <NavLink to="account-overview">
                    <li className={`nav-link`} title="Account Overview">
                      <AiFillHome className="user_profile_icon" />
                      <span className={`user_profile_text nav-text `}>
                        Account Overview
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="edit-profile">
                    <li className={`nav-link`} title="Edit Profile">
                      <BsPencilFill className="user_profile_icon" />
                      <span className={`user_profile_text nav-text `}>
                        Edit Profile
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="change-password" title="Change Password">
                    <li className={`nav-link`}>
                      <HiLockClosed className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Change Password
                      </span>
                    </li>
                  </NavLink>
                  {/* <NavLink title="Delete Account" onClick={deleteAccount}>
                    <li className={`nav-link`}>
                      <AiOutlineDelete className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Delete Account
                      </span>
                    </li>
                  </NavLink>
                  <NavLink title="Sign Out" onClick={logoutUser}>
                    <li className={`nav-link`}>
                      <FiLogOut className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Sign Out
                      </span>
                    </li>
                  </NavLink> */}

                  {/* <NavLink
                    to="notification-settings"
                    title="Notification Settings"
                  >
                    <li className={`nav-link`}>
                      <MdNotifications className="user_profile_icon" />
                      <span className="user_profile_text  nav-text me-3">
                        Notification Settings
                      </span>
                    </li>
                  </NavLink> */}

                  {/* <NavLink to="receipts">
                    <li className={`nav-link`}>
                      <BiTimeFive className="user_profile_icon" />
                      <div className="d-none d-sm-block">
                        <span className="user_profile_text nav-text me-3">
                          Receipts
                        </span>
                      </div>
                    </li>
                  </NavLink> */}

                  <Link to="" onClick={deleteAccount} className="">
                    <li className="nav-link">
                      <AiOutlineDelete className="user_profile_icon" />
                      <span className="user_profile_text nav-text">
                        Delete Account
                      </span>
                    </li>
                  </Link>
                  <Link to="" onClick={logoutUser} className="user_logout">
                    <li className="nav-link">
                      <FiLogOut className="user_profile_icon" />
                      <span className="user_profile_text nav-text">Logout</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section className="user-profile-rightbar">
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserProfile2;
