import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../../Component/Custom Loader/CustomLoader";
import { ToastSuccess } from "../../../../Component/ToastAlert";

const UserSingleTicket = () => {
  const { ticketId } = useParams();
  const reply_message = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Initial table data
  const [ticketInfo, setTicketInfo] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/support/ticket/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setTicketInfo(res.data?.info);
        setIsLoading(false);
        setError(false);
      });
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PATCH");
    formdata.append("reply_message", reply_message.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}user/support/ticket/reply/${ticketId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("user_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          renderAllInfo();
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 1000);
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Ticket Details</h5>
                  <Link to="/user/ticket">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Back
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* if data get */}
        {!isLoading && !error && ticketInfo != null && (
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <h6>
                    Subject: <span>{ticketInfo?.subject}</span>
                  </h6>
                  <h6>
                    Message: <span>{ticketInfo?.message}</span>
                  </h6>
                  {ticketInfo?.status == 0 ? (
                    <h6>
                      status:
                      <span> Pending Ticket</span>
                    </h6>
                  ) : ticketInfo?.status == 1 ? (
                    <h6>
                      status:
                      <span> Open Ticket</span>
                    </h6>
                  ) : ticketInfo?.status == 2 ? (
                    <h6>
                      status:
                      <span> Closed Ticket</span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {ticketInfo?.last_reply != null && (
                    <h6>
                      Last Reply:
                      <span> {ticketInfo?.last_reply?.reply_message}</span>
                    </h6>
                  )}
                  <Form className="mt-5" onSubmit={updatedData}>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationCustom01"
                      className="mb-3"
                    >
                      <Form.Label className="label fw-bold">
                        Reply&nbsp;{""}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Reply"
                        ref={reply_message}
                      />
                    </Form.Group>
                    <Button type="submit" className="mt-2 rounded-3 border-0">
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading && !error && (
          <>
            <div className="d-flex justify-content-center pb-5 pt-5">
              <DotLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserSingleTicket;
