/*===================
    sign in  INDEX  
=====================
1. variable 
2. main function

  a. notification function
  b. Form submit function
  c. Password show hide password function
  d. notification
  e. Email
  f. Password
  g. Remember me & Forget Password
  h. Log In button
  i. Sign Up button

================*/

import React, { useRef, useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import logo_long from "../../Assets/login_logo.png";
import logo_long from "../../Assets/login_logoo.png";
import Cookie from "cookie-universal";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../Utils/GlobalVariables";
import { ToastSuccess } from "../../Component/ToastAlert";
import getCookie from "../../Component/Cookie/Get.Cookie";

const SignIn = () => {
  const email = useRef();
  const password = useRef();

  const cookies = Cookie();

  // notification function
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || "/user";
  // Form submit function
  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append("email", email.current.value);
    formdata.append("password", password.current.value);
    formdata.append("visitor_unique_id", getCookie("VISITOR_UNIQUE_ID"));

    axios
      .post(`${BACKEND_BASE_URL}user/login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response?.data?.data?.status === 200) {
          cookies.set(
            "user_access_token",
            response?.data?.data?.user_access_token
            // {
            //   maxAge: 60 * 60 * 24 * 7,
            // }
          );
          cookies.set("user_id", response.data?.data?.user_id);
          navigate(from, { replace: true });
          ToastSuccess.fire({
            icon: "success",
            title: "Successfully logged in...!",
          });
          e.target.reset();
        } else if (response?.data?.status == 401) {
          ToastSuccess.fire({
            icon: "warning",
            title: response?.data?.message,
          });
          e.target.reset();
        } else if (response?.data?.status == 404) {
          ToastSuccess.fire({
            icon: "warning",
            title: response?.data?.message,
          });
        }
      });
    e.preventDefault();
  };

  // Password show hide password function
  const [passType, setPassType] = useState("password");
  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
    }
    if (passType == "text") {
      setPassType("password");
    }
  };

  // const user_access_token = localStorage.getItem("user_access_token");
  // useEffect(() => {
  //   if (user_access_token) {
  //     // navigate("/user");
  //   }
  // }, []);

  return (
    <div className=" logIn d-flex justify-content-center align-items-center ">
      {/* notification */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg="success"
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header className="rounded-0 border-0 ">
            <strong className="me-auto">Email Sent Successfully</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
      <div className="custom_container">
        <div className="logInForm">
          <div className="w-100 text-center mb-1">
            <img src={logo_long} alt="Themepoints" className="img-fluid " />
          </div>
          <Form className="form" onSubmit={handleSubmit}>
            {/* Email */}
            <Form.Group className="form_group pb-3">
              <Form.Label>
                {/* Email <span className="text-danger">*</span> */}
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter email *"
                name="email"
                ref={email}
                required
              />
            </Form.Group>

            {/* Password */}
            <Form.Group className="form_group position-relative pb-3">
              <Form.Label>
                {/* Password <span className="text-danger">*</span> */}
              </Form.Label>
              <Form.Control
                type={passType}
                id="password"
                placeholder="Enter password *"
                name="password"
                required
                ref={password}
              />

              <div className="eye_icon" onClick={handlePasswordType}>
                {passType == "password" ? (
                  <AiFillEye size="1.2em" />
                ) : (
                  <AiFillEyeInvisible size="1.2em" />
                )}
              </div>
            </Form.Group>

            {/* Remember me & Forget Password */}
            <Form.Group className="d-flex justify-content-end pb-4 font_size_14 fw_600">
              {/* <Form.Check type="checkbox" label="Remember me" required /> */}

              <p className="m-0 link_tag">
                <Link to="/forget-password?" className="cl_gold">
                  Forget password?
                </Link>
              </p>
            </Form.Group>

            {/* Log In button */}
            <Button type="submit" className="w-100 login_btn mb-3">
              Log In
            </Button>

            {/* Sign Up button */}
            <p className="text-center font_size_14 fw_600 link_tag">
              Don't have an account?
              <Link to="/new-user" className="ms-2 cl_gold">
                Sign Up
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
