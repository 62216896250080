/*===================
    wishlist  INDEX  
=====================
1. variable 
2. main function

  a. Header component
  b. wishlist
    i.  wishlist_table
  c. footer 

================*/

// variable

import React from "react";
import { Table } from "react-bootstrap";
import Footer from "../../Component/Common/Footer/Footer";
import Header from "../../Component/Common/Header/Header";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";
import test from "../../Assets/test.jpg";

const WishList = () => {
  return (
    <>
      <Header />
      {/* wishlist */}
      <div className="wishlist sesction_pt sesction_pb">
        <div className="custom_container">
          {/* wishlist_table */}
          <div className="wishlist_table">
            <Table responsive>
              <thead>
                <tr>
                  <th>PRODUCT NAME</th>
                  <th></th>
                  <th>SKU</th>
                  <th>UNIT PRICE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table_image">
                    <img className="img-fluid" src={test} alt="" />
                  </td>
                  <td>
                    <h5 className="font_size_14 fw_700 ">
                      TANJIM SQUAD JACKET
                    </h5>
                    <p className="mb-1 font_size_14 fw_700 ">
                      <FaChevronRight /> Color:
                    </p>
                    <p className="font_size_14 fw_700">
                      <FaChevronRight /> Size: M
                    </p>
                  </td>
                  <td className="font_size_14 fw_600 ">10002736</td>
                  <td className="font_size_14 fw_600 ">TK. 6106</td>
                  <td className="font_size_14 fw_600 cl_lightGrey ">
                    <AiOutlineCloseCircle size={30} className="pointer" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WishList;
