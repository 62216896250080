import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import "../Category/Keywords.css";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const FaqEdit = () => {
  const { faqId } = useParams();
  const title = useRef();
  const details = useRef();
  const faq_catId = useRef();

  // Initial table data
  const [updateInfo, setUpdateInfo] = useState([]);

  // Get Single Info
  const renderUpdateInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/faq/show-single/${faqId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setUpdateInfo(res.data?.data);
      });
  };

  // Initial add info
  const [addInfo, setAddInfo] = useState([]);
  // Get add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/faq/category`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setAddInfo(res.data?.data);
      });
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    // formdata.append("_method", "PUT");
    formdata.append("_method", "PATCH");

    formdata.append("title", title.current.value);
    formdata.append("details", details.current.value);
    formdata.append("faq_catId", faq_catId.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin/faq/update/${faqId}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response.data?.status === "ok") {
          ToastSuccess.fire({
            icon: "success",
            title: "FAQ update successfully...!",
          });
          e.target.reset();
          renderUpdateInfo();
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 1000);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderUpdateInfo();
    renderAddInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Update FAQ Info</div>
                  <Link to="/admin/faq/category">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={updatedData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add name */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="title"
                              ref={title}
                              defaultValue={updateInfo?.title}
                            />
                          </Form.Group>

                          {/* Parent Category */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Category
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={faq_catId}
                            >
                              {addInfo?.map((data) => (
                                <option
                                  value={data?.id}
                                  key={data.id}
                                  selected={
                                    data?.id == updateInfo?.faq_cat?.id
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Details&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              ref={details}
                              defaultValue={updateInfo?.details}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FaqEdit;
