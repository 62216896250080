import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { createContext, Suspense, useEffect, useState } from "react";
import Loader from "./Utils/Loader/Loader";
import ScrollToTop from "./Utils/ScrollToTop/ScrollToTop";
import Cart from "./Pages/Cart/Cart";
import SignIn from "./Pages/Login/SignIn";
import SignUp from "./Pages/Login/SignUp";
import ForgetPass from "./Pages/Login/ForgetPass";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import Admin from "./Pages/Admin/Admin";
import AdminLogin from "./Pages/Admin/AdminLogin/AdminLogin";
import AdminPrivateRoute from "./Pages/Admin/PrivateRoute/AdminPrivateRoute";
import Category from "./Pages/Admin/Category/Category";
import CategoryAdd from "./Pages/Admin/Category/CategoryAdd";
import CategoryEdit from "./Pages/Admin/Category/CategoryEdit";
import SubCategory from "./Pages/Admin/SubCategory/SubCategory";
import SubCategoryAdd from "./Pages/Admin/SubCategory/SubCategoryAdd";
import SubCategoryEdit from "./Pages/Admin/SubCategory/SubCategoryEdit";
import ChildCategory from "./Pages/Admin/ChildCategory/ChildCategory";
import ChildCategoryAdd from "./Pages/Admin/ChildCategory/ChildCategoryAdd";
import ChildCategoryEdit from "./Pages/Admin/ChildCategory/ChildCategoryEdit";
import Brand from "./Pages/Admin/Brand/Brand";
import BrandAdd from "./Pages/Admin/Brand/BrandAdd";
import BrandEdit from "./Pages/Admin/Brand/BrandEdit";
import Tax from "./Pages/Admin/Tax/Tax";
import TaxAdd from "./Pages/Admin/Tax/TaxAdd";
import TaxEdit from "./Pages/Admin/Tax/TaxEdit";
import Product from "./Pages/Admin/Product/Product";
import ProductAdd from "./Pages/Admin/Product/ProductAdd";
import ProductEdit from "./Pages/Admin/Product/ProductEdit";
import CampaignOffer from "./Pages/Admin/CampaignOffer/CampaignOffer";
import WishList from "./Pages/WishList/WishList";
import StockOutProduct from "./Pages/Admin/StockOutProduct/StockOutProduct";
import ManageFaq from "./Pages/Admin/ManageFaq/ManageFaq";
import FaqCategory from "./Pages/Admin/ManageFaq/FaqCategory";
import FaqCategoryAdd from "./Pages/Admin/ManageFaq/FaqCategoryAdd";
import FaqCategoryEdit from "./Pages/Admin/ManageFaq/FaqCategoryEdit";
import FaqAdd from "./Pages/Admin/ManageFaq/FaqAdd";
import FaqEdit from "./Pages/Admin/ManageFaq/FaqEdit";
import ManageBlog from "./Pages/Admin/ManageBlog/ManageBlog";
import BlogCategory from "./Pages/Admin/ManageBlog/BlogCategory";
import BlogCategoryAdd from "./Pages/Admin/ManageBlog/BlogCategoryAdd";
import BlogCategoryEdit from "./Pages/Admin/ManageBlog/BlogCategoryEdit";
import BlogAdd from "./Pages/Admin/ManageBlog/BlogAdd";
import BlogEdit from "./Pages/Admin/ManageBlog/BlogEdit";
import Coupons from "./Pages/Admin/CouponManagement/Coupons";
import CouponAdd from "./Pages/Admin/CouponManagement/CouponAdd";
import CouponEdit from "./Pages/Admin/CouponManagement/CouponEdit";
import Shipping from "./Pages/Admin/ShippingManagement/Shipping";
import ShippingAdd from "./Pages/Admin/ShippingManagement/ShippingAdd";
import ShippingEdit from "./Pages/Admin/ShippingManagement/ShippingEdit";
import Subscriberlist from "./Pages/Admin/SubscriberList/Subscriberlist";
import EmailSetting from "./Pages/Admin/Settings/EmailSetting";
import GeneralSetting from "./Pages/Admin/Settings/GeneralSetting";
import SMSSetting from "./Pages/Admin/Settings/SMSSetting";
import Maintenance from "./Pages/Admin/Settings/Maintenance";
import UserPrivateRoute from "./Pages/User/PrivateRoute/UserPrivateRoute";
import User from "./Pages/User/User";
import Currency from "./Pages/Admin/CurrencyManagement/Currency";
import CurrencyAdd from "./Pages/Admin/CurrencyManagement/CurrencyAdd";
import CurrencyEdit from "./Pages/Admin/CurrencyManagement/CurrencyEdit";
import AllOrders from "./Pages/Admin/Orders/AllOrders";
import PendingOrders from "./Pages/Admin/Orders/PendingOrders";
import InProgressOrder from "./Pages/Admin/Orders/InProgressOrder";
import DeliveredOrders from "./Pages/Admin/Orders/DeliveredOrders";
import axios, { CanceledError } from "axios";
import CanceledOrders from "./Pages/Admin/Orders/CanceledOrders";
import SingleOrder from "./Pages/Admin/Orders/SingleOrder";
import UserProfileDashboard from "./Pages/User/User Profile Dashboard/UserProfileDashboard";
import NotificationSettings from "./Pages/User/User Profile Dashboard/NotificationSettings";
import ReceiptDetails from "./Pages/User/User Profile Dashboard/ReceiptDetails";
import UserProfileEdit from "./Pages/User/User Profile Dashboard/UserProfileEdit";
import UserPassChange from "./Pages/User/User Profile Dashboard/UserPassChange";
import UserReceipts from "./Pages/User/User Profile Dashboard/UserReceipts";
import Customerlist from "./Pages/Admin/CustomerList/Customerlist";
import { BACKEND_BASE_URL } from "./Utils/GlobalVariables";
import getCookie from "./Component/Cookie/Get.Cookie";
import ServerUnderMaintenance from "./Pages/ServerUnderMaintenance/ServerUnderMaintenance";
import Cookie from "cookie-universal";
import UsersOrder from "./Pages/User/User Profile Dashboard/UsersOrder";
import UserSingleOrder from "./Pages/User/User Profile Dashboard/UserSingleOrder";
import UserCart from "./Pages/User/User Profile Dashboard/UserCart";
import ManageTickets from "./Pages/Admin/SupportTicket/ManageTickets";
import TicketAdd from "./Pages/Admin/SupportTicket/TicketAdd";
import SupportTicket from "./Pages/User/User Profile Dashboard/SupportTicket/SupportTicket";
import Dashboard from "./Pages/User/User Profile Dashboard/Dashboard/Dashboard";
import NewTicket from "./Pages/User/User Profile Dashboard/SupportTicket/NewTicket";
import UserSingleTicket from "./Pages/User/User Profile Dashboard/SupportTicket/UserSingleTicket";
import AdminViewTicket from "./Pages/Admin/SupportTicket/AdminViewTicket";
import PopularCategory from "./Pages/Admin/PopularCategory";
import CategoryProducts from "./Pages/HomeProduct/CategoryProducts";
import AllBlog from "./Pages/HomeBlog/AllBlog";
import BlogDetails from "./Pages/HomeBlog/BlogDetails";
import LatestProducts from "./Pages/HomeProduct/LatestProducts";
import AllCategory from "./Pages/Category/AllCategory";

const Home = React.lazy(() => import("./Pages/Home/Home"));
const Home2 = React.lazy(() => import("./Pages/Home2/Home2"));
const HomeProduct = React.lazy(() => import("./Pages/HomeProduct/HomeProduct"));
const HomeProductDetails = React.lazy(() =>
  import("./Pages/HomeProductDetails/HomeProductDetails")
);
const Checkout = React.lazy(() => import("./Pages/Checkout/Checkout"));
const AboutUs = React.lazy(() => import("./Pages/AboutUs/AboutUs"));

export const USerContext = createContext();

function App() {
  const [cart, setCart] = useState(1);
  const [wishlist, setWishlist] = useState(1);

  const cookies = Cookie();

  // console.log( "app js file",(getCookie(
  //   "VISITOR_UNIQUE_ID"
  // )));

  const [maintenanceMode, setMaintenanceMode] = useState();
  const [maintenancePermission, setMaintenancePermission] = useState(false);

  const ServerMainCheck = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}server-maintenance-check/${getCookie(
          "VISITOR_UNIQUE_ID"
        )}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          setMaintenanceMode(res.data?.maintenance_data?.status);
          setMaintenancePermission(res.data?.requestor_info?.permission);
 
          // document.cookie =
          //   "VISITOR_UNIQUE_ID=" +
          //   res?.data?.requestor_info?.visitors_unique_id +
          //   "; max-age=" +
          //   1 * 24 * 60 * 60;
          if (!getCookie("VISITOR_UNIQUE_ID")) {
            cookies.set(
              "VISITOR_UNIQUE_ID",
              res?.data?.requestor_info?.visitors_unique_id,
              {
                maxAge: 60 * 60 * 24 * 1,
                path: "*",
              }
            );
          }

          // cookies.set(
          //   "VISITOR_UNIQUE_ID",
          //   res?.data?.requestor_info?.visitors_unique_id,
          //   {
          //     maxAge: 60 * 60 * 24 * 7,
          //   }
          // );
        }
      });
  };

  useEffect(() => {
    ServerMainCheck();
  }, []);

  return (
    <>
      {(maintenanceMode == "0" ||
        (maintenanceMode == "1" && maintenancePermission == true)) && (
        <USerContext.Provider value={{ cart, setCart, wishlist, setWishlist }}>
          <Router>
            <Suspense fallback={<Loader />}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home2" element={<Home2 />} />

                <Route path="/blogs" element={<AllBlog />} />
                <Route path="/blog-details/:blogId" element={<BlogDetails />} />

                <Route path="product" element={<HomeProduct />} />

                <Route
                  path="products/:cat_slug"
                  element={<CategoryProducts />}
                />
                <Route path="products/latest" element={<LatestProducts />} />
                <Route
                  path="product-details/:productId"
                  element={<HomeProductDetails />}
                />
                {/* <Route path="product-details" element={<HomeProductDetails />} /> */}

                <Route path="categories" element={<AllCategory />} />

                <Route path="checkout" element={<Checkout />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="cart" element={<Cart />} />
                <Route path="wishlist" element={<WishList />} />
                <Route path="login" element={<SignIn />} />
                <Route path="new-user" element={<SignUp />} />
                <Route path="forget-password" element={<ForgetPass />} />

                {/* Admin Dashboard start  */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="admin"
                  element={
                    <AdminPrivateRoute>
                      <Admin />{" "}
                    </AdminPrivateRoute>
                  }
                >
                  <Route index element={<AdminDashboard />} />
                  <Route path="category" element={<Category />} />
                  <Route path="category/add" element={<CategoryAdd />} />
                  <Route
                    path="category/edit/:catId"
                    element={<CategoryEdit />}
                  />
                  <Route path="subcategory" element={<SubCategory />} />
                  <Route path="subcategory/add" element={<SubCategoryAdd />} />
                  <Route
                    path="subcategory/edit/:subCatId"
                    element={<SubCategoryEdit />}
                  />
                  <Route path="childcategory" element={<ChildCategory />} />
                  <Route
                    path="childcategory/add"
                    element={<ChildCategoryAdd />}
                  />
                  <Route
                    path="childcategory/edit/:childCatId"
                    element={<ChildCategoryEdit />}
                  />
                  <Route path="brand" element={<Brand />} />
                  <Route path="brand/add" element={<BrandAdd />} />
                  <Route path="brand/edit/:brandId" element={<BrandEdit />} />
                  <Route path="tax" element={<Tax />} />
                  <Route path="tax/add" element={<TaxAdd />} />
                  <Route path="tax/edit/:taxId" element={<TaxEdit />} />

                  <Route path="product" element={<Product />} />
                  <Route path="product/add" element={<ProductAdd />} />
                  <Route
                    path="product/edit/:productId"
                    element={<ProductEdit />}
                  />
                  <Route path="campaign" element={<CampaignOffer />} />
                  <Route
                    path="stock/out/product"
                    element={<StockOutProduct />}
                  />

                  <Route path="faq" element={<ManageFaq />} />
                  <Route path="faq/create" element={<FaqAdd />} />
                  <Route path="faq/edit/:faqId" element={<FaqEdit />} />

                  <Route path="faqcategory" element={<FaqCategory />} />
                  <Route
                    path="faqcategory/create"
                    element={<FaqCategoryAdd />}
                  />
                  <Route
                    path="faqcategory/edit/:fcatId"
                    element={<FaqCategoryEdit />}
                  />

                  <Route path="blog" element={<ManageBlog />} />
                  <Route path="blog/create" element={<BlogAdd />} />
                  <Route path="blog/edit/:blogId" element={<BlogEdit />} />

                  <Route path="blogcategory" element={<BlogCategory />} />
                  <Route
                    path="blogcategory/create"
                    element={<BlogCategoryAdd />}
                  />
                  <Route
                    path="blogcategory/edit/:blogCatId"
                    element={<BlogCategoryEdit />}
                  />

                  <Route path="coupon" element={<Coupons />} />
                  <Route path="coupon/create" element={<CouponAdd />} />
                  <Route path="coupon/edit/:cpnId" element={<CouponEdit />} />

                  <Route path="shipping" element={<Shipping />} />
                  <Route path="shipping/create" element={<ShippingAdd />} />
                  <Route
                    path="shipping/edit/:shippingId"
                    element={<ShippingEdit />}
                  />

                  <Route path="orders/type=all" element={<AllOrders />} />
                  <Route
                    path="orders/invoice/:orderId"
                    element={<SingleOrder />}
                  />

                  <Route
                    path="orders/type=Pending"
                    element={<PendingOrders />}
                  />
                  <Route
                    path="orders/type=in-progress"
                    element={<InProgressOrder />}
                  />
                  <Route
                    path="orders/type=Delivered"
                    element={<DeliveredOrders />}
                  />
                  <Route
                    path="orders/type=Canceled"
                    element={<CanceledOrders />}
                  />

                  <Route path="currency" element={<Currency />} />
                  <Route path="currency/create" element={<CurrencyAdd />} />
                  <Route
                    path="currency/edit/:currencyId"
                    element={<CurrencyEdit />}
                  />

                  <Route path="subscribers" element={<Subscriberlist />} />

                  <Route path="customers" element={<Customerlist />} />

                  <Route path="ticket" element={<ManageTickets />} />
                  <Route path="ticket/create" element={<TicketAdd />} />
                  <Route
                    path="ticket/:ticketId"
                    element={<AdminViewTicket />}
                  />

                  <Route path="email/setting" element={<EmailSetting />} />
                  <Route path="general/setting" element={<GeneralSetting />} />
                  <Route path="sms/setting" element={<SMSSetting />} />
                  <Route path="maintenance/setting" element={<Maintenance />} />
                  <Route
                    path="popular-categories"
                    element={<PopularCategory />}
                  />
                </Route>

                {/*===================== user Dashboard ===========================*/}
                <Route path="/user-registration" element={<SignUp />} />
                <Route path="/user/login" element={<SignIn />} />
                <Route
                  path="/user/"
                  element={
                    <UserPrivateRoute>
                      <User />{" "}
                    </UserPrivateRoute>
                  }
                >
                  {/* <Route index element={<UserProfileDashboard />} /> */}
                  <Route index element={<Dashboard />} />
                  <Route
                    path="account-overview"
                    element={<UserProfileDashboard />}
                  />
                  <Route path="edit-profile" element={<UserProfileEdit />} />
                  <Route path="change-password" element={<UserPassChange />} />
                  <Route path="ticket" element={<SupportTicket />} />
                  <Route path="ticket/new" element={<NewTicket />} />
                  <Route
                    path="ticket/:ticketId"
                    element={<UserSingleTicket />}
                  />
                  <Route path="orders" element={<UsersOrder />} />
                  <Route path="orders/:orderId" element={<UserSingleOrder />} />
                  <Route path="cart" element={<UserCart />} />
                  <Route
                    path="notification-settings"
                    element={<NotificationSettings />}
                  />
                  <Route path="receipts" element={<UserReceipts />} />
                  <Route
                    path="receipts/:receiptId"
                    element={<ReceiptDetails />}
                  />
                </Route>
                {/* <Route path="user/cart" element={<UserCart />} /> */}
              </Routes>
            </Suspense>
          </Router>
        </USerContext.Provider>
      )}

      {maintenanceMode == "1" && maintenancePermission == false && (
        <Router>
          <Routes>
            <Route path="/*" element={<ServerUnderMaintenance />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
