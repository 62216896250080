/*===================
    relatedProduct details  INDEX  
=====================
1. variable 
2. slider setting arrow design

  a. right arrow design
  b. left arrow design

3. main function

  a. slider setting
  b. relatedProduct 
  c. slider
    i. single content 

================*/

// variable

import React, { useContext, useState } from "react";
import "./RelatedProduct.css";
// import test from "../../../Assets/test.jpg";
import { Link } from "react-router-dom";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { AiOutlineHeart } from "react-icons/ai";
import Slider from "react-slick";
import { Figure, Toast, ToastContainer } from "react-bootstrap";
import { USerContext } from "../../../App";
import { fileUrl } from "../../../Utils/GlobalVariables";

// slider setting arrow design
// right arrow design
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronRight
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        color: "black",
        width: 35,
        height: 35,
        zIndex: 9,
        right: -15,
        borderRadius: "50%",
        padding: 7,
      }}
      onClick={onClick}
    />
  );
}
// left arrow design
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        color: "black",
        width: 35,
        height: 35,
        zIndex: 9,
        left: -15,
        borderRadius: "50%",
        padding: 7,
      }}
      onClick={onClick}
    />
  );
}

// main function
const RelatedProduct = ({ similarProduct, sliderDataValue }) => {
  // slider setting
  const settings2 = {
    infinite: sliderDataValue,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: sliderDataValue,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: sliderDataValue,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: sliderDataValue,
          arrows: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: sliderDataValue,
          arrows: false,
          swipe: true,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const { wishlist, setWishlist } = useContext(USerContext);

  // notification function
  const [show, setShow] = useState(false);
  const wishlistCounter = () => {
    setWishlist(wishlist + 1);
    setShow(true);
  };

  return (
    <>
      {/* {similarProduct?.map((data, i) => (
        <Figure key={data?.id}>
          <Link to="/product-details">
            <Figure.Image
              height={180}
              className="w-100"
              alt="171x180"
              src={`${fileUrl}${data?.featured_image}`}
            />
          </Link>
          <Figure.Caption className="d-flex">
            <div className="left">
              <Link to="/product-details" className="underline_none">
                <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                  {data?.name}
                </p>
              </Link>
              <p className="mb-0 cl_lightGrey">
                {data.categories.name}
                <br />
                {data.brand.name}
              </p>
              <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                {data.price_including_tax > 0 ? (
                  <>
                    ${data?.price_including_tax}{" "}
                    <span className="font_size_16 fw_200">(including vat)</span>
                  </>
                ) : (
                  <>${data?.current_price} </>
                )}
              </p>
            </div>
            <div className="right ms-auto">
              <div className="icon p-2 d-flex justify-content-center align-items-center">
                <AiOutlineHeart size={16} onClick={wishlistCounter} />
              </div>
            </div>
          </Figure.Caption>
        </Figure>
      ))} */}

      {/* relatedProduct */}
      <div className="relatedProduct">
        {/* notification */}
        <ToastContainer position="top-end" className="p-3">
          <Toast
            bg="success"
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className="rounded-0 border-0 ">
              <strong className="me-auto">
                Successfully Added To Wishlist
              </strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <h2 className="font_size_18 fw_700 px-4 pt-4 ">Related Products</h2>

        <div className=" m-4">
          <div className="collection_content">
            {/* {similarProduct.length < 5 ? (
              <>
                {similarProduct.map((data, i) => (
                  <Figure key={data?.id} style={{ padding: "0 10px" }}>
                    <Link
                      to={`/product-details/${data?.id}`}
                      onClick={() =>
                        setTimeout(() => {
                          window.location.reload();
                        }, 100)
                      }
                    >
                      <Figure.Image
                        height={180}
                        className="w-100"
                        alt="171x180"
                        src={`${fileUrl}${data?.featured_image}`}
                      />
                    </Link>
                    <Figure.Caption className="d-flex">
                      <div className="left">
                        <Link
                          to={`/product-details/${data?.id}`}
                          className="underline_none"
                        >
                          <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                            {data?.name}
                          </p>
                        </Link>
                        <p className="mb-0 cl_lightGrey">
                          {data.categories.name}
                          <br />
                          {data.brand.name}
                        </p>
                        <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                          {data.price_including_tax > 0 ? (
                            <>
                              ${data?.price_including_tax}{" "}
                              <span className="font_size_16 fw_200">
                                (including vat)
                              </span>
                            </>
                          ) : (
                            <>${data?.current_price} </>
                          )}
                        </p>
                      </div>
                      <div className="right ms-auto">
                        <div className="icon p-2 d-flex justify-content-center align-items-center">
                          <AiOutlineHeart size={16} onClick={wishlistCounter} />
                        </div>
                      </div>
                    </Figure.Caption>
                  </Figure>
                ))}
              </>
            ) : (
              <>
                <Slider {...settings2}>
                  {similarProduct.map((data, i) => (
                    <Figure key={data?.id}>
                      <Link
                        to={`/product-details/${data?.id}`}
                        onClick={() =>
                          setTimeout(() => {
                            window.location.reload();
                          }, 100)
                        }
                      >
                        <Figure.Image
                          height={180}
                          className="w-100"
                          alt="171x180"
                          src={`${fileUrl}${data?.featured_image}`}
                        />
                      </Link>
                      <Figure.Caption className="d-flex">
                        <div className="left">
                          <Link
                            to={`/product-details/${data?.id}`}
                            className="underline_none"
                          >
                            <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                              {data?.name}
                            </p>
                          </Link>
                          <p className="mb-0 cl_lightGrey">
                            {data.categories.name}
                            <br />
                            {data.brand.name}
                          </p>
                          <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                            {data.price_including_tax > 0 ? (
                              <>
                                ${data?.price_including_tax}{" "}
                                <span className="font_size_16 fw_200">
                                  (including vat)
                                </span>
                              </>
                            ) : (
                              <>${data?.current_price} </>
                            )}
                          </p>
                        </div>
                        <div className="right ms-auto">
                          <div className="icon p-2 d-flex justify-content-center align-items-center">
                            <AiOutlineHeart
                              size={16}
                              onClick={wishlistCounter}
                            />
                          </div>
                        </div>
                      </Figure.Caption>
                    </Figure>
                  ))}
                </Slider>
              </>
            )} */}

            {/* slider */}
            <Slider {...settings2}>
              {/* slider single content */}

              {similarProduct.map((data, i) => (
                <Figure key={data?.id}>
                  <Link
                    style={{ outline: "0" }}
                    to={`/product-details/${data?.id}`}
                  >
                    <Figure.Image
                      height={180}
                      className="w-100"
                      alt="171x180"
                      src={`${fileUrl}${data?.featured_image}`}
                    />
                  </Link>
                  <Figure.Caption className="d-flex">
                    <div className="left">
                      <Link
                        to={`/product-details/${data?.id}`}
                        className="underline_none"
                      >
                        <p className="mb-0 cl_lightDark2 font_size_16 fw_700">
                          {data?.name}
                        </p>
                      </Link>
                      <p className="mb-0 cl_lightGrey">
                        {data.categories.name}
                        <br />
                        {data.brand.name}
                      </p>
                      <p className="mb-0 cl_lightDark2 font_size_16 fw_500">
                        <>
                          {data?.previous_price != null ? (
                            <span className="text-decoration-line-through text-danger">
                              ${data?.previous_price}
                            </span>
                          ) : (
                            ""
                          )}
                        </>{" "}
                        {data.price_including_tax > 0 ? (
                          <>
                            ${data?.price_including_tax}{" "}
                            <span className="font_size_16 fw_200">
                              (including vat)
                            </span>
                          </>
                        ) : (
                          <>${data?.current_price} </>
                        )}
                      </p>
                    </div>
                    <div className="right ms-auto">
                      <div className="icon p-2 d-flex justify-content-center align-items-center">
                        <AiOutlineHeart size={16} onClick={wishlistCounter} />
                      </div>
                    </div>
                  </Figure.Caption>
                </Figure>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedProduct;
