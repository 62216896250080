import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const ReceiptDetails = () => {
  const { receiptId } = useParams();
  const navigate = useNavigate();
  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Receipt {receiptId}</h1>

      <div className="receipt_details">
        <Form.Group className="d-flex align-items-center form_group">
          <Form.Label className="w-25 mb-0">Date</Form.Label>
          <Form.Control
            className=""
            type="text"
            placeholder="Aug 12,2020"
            // defaultValue={userInfo?.name}
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-center form_group">
          <Form.Label className="w-25 mb-0">Order Number</Form.Label>
          <Form.Control
            className=""
            type="text"
            placeholder="Email"
            defaultValue={receiptId}
          />
        </Form.Group>
        <div className="mt-5 view_order_btn">
          <div onClick={() => navigate(-1)}>View Order</div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptDetails;
