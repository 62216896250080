/*===================
    signup  INDEX  
=====================
1. variable 
2. main function

  a. notification function
  b. form submit function
  c. show hide password function
  d. notification
  e. Email
  f. Password
  g. Remember me
  h. Sign up button
  i. Sign in button

================*/

// variable

import React, { useRef, useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import logo_long from "../../Assets/login_logo.png";
// import logo_long from "../../Assets/Home/logo_long.png";
import logo_long from "../../../src/Assets/Home/logo_long.png";
import getCookie from "../../Component/Cookie/Get.Cookie";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../Utils/GlobalVariables";
import Cookie from "cookie-universal";
import { ToastSuccess } from "../../Component/ToastAlert";

const SignUp = () => {
  const cookies = Cookie();
  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || "/login";

  const name = useRef();
  const email = useRef();
  const password = useRef();
  const confirm_password = useRef();

  // notification function
  const [show, setShow] = useState(false);

  // form submit function
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   e.target.reset();
  //   setShow(true);
  // };

  // Form submit function
  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append("name", name.current.value);
    formdata.append("email", email.current.value);
    formdata.append("password", password.current.value);
    formdata.append("confirm_password", confirm_password.current.value);
    // formdata.append("visitor_unique_id", getCookie("VISITOR_UNIQUE_ID"));

    axios
      .post(`${BACKEND_BASE_URL}user/registration`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response?.data?.data?.status === 200) {
          cookies.set(
            "user_access_token",
            response?.data?.data?.user_access_token
            // {
            //   maxAge: 60 * 60 * 24 * 7,
            // }
          );
          // cookies.set("user_id", response.data?.data?.user_id);
          navigate(from, { replace: true });
          ToastSuccess.fire({
            icon: "success",
            title: "Registration Successful...!",
          });
          e.target.reset();
        } else if (response?.data?.status == 401) {
          ToastSuccess.fire({
            icon: "warning",
            title: response?.data?.message,
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };

  // show hide password function
  const [passType, setPassType] = useState("password");
  const [passType2, setPassType2] = useState("password");

  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
    }
    if (passType == "text") {
      setPassType("password");
    }
  };

  const handlePasswordType2 = () => {
    if (passType2 == "password") {
      setPassType2("text");
    } else if (passType2 == "text") {
      setPassType2("password");
    }
  };

  return (
    <div className=" logIn d-flex justify-content-center align-items-center ">
      {/* notification */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg="success"
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header className="rounded-0 border-0 ">
            <strong className="me-auto">Accoutn Created Successfully</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>

      <div className="custom_container">
        <div className="logInForm">
          <div className="w-100 text-center mb-5">
            <img src={logo_long} alt="Themepoints" className="img-fluid " />
          </div>

          <Form className="form" onSubmit={handleSubmit}>
            {/* Name */}
            <Form.Group className="form_group pb-3">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name *"
                name="name"
                required
                ref={name}
              />
            </Form.Group>

            {/* Email */}
            <Form.Group className="form_group pb-3">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter email *"
                name="email"
                ref={email}
                required
              />
            </Form.Group>

            {/* Password */}
            <Form.Group className="form_group position-relative pb-3">
              <Form.Label></Form.Label>
              <Form.Control
                type={passType}
                id="password"
                placeholder="Password *"
                name="password"
                ref={password}
                required
              />
              <div className="eye_icon" onClick={handlePasswordType}>
                {passType == "password" ? (
                  <AiFillEye size="1.2em" />
                ) : (
                  <AiFillEyeInvisible size="1.2em" />
                )}
              </div>
            </Form.Group>

            <Form.Group className="form_group position-relative pb-3">
              <Form.Control
                type={passType2}
                id="confirm_password"
                placeholder="Confirm Password *"
                name="confirm_password"
                ref={confirm_password}
                required
              />

              <div className="eye_icon " onClick={handlePasswordType2}>
                {passType2 == "password" ? (
                  <AiFillEye size="1.2em" />
                ) : (
                  <AiFillEyeInvisible size="1.2em" />
                )}
              </div>
            </Form.Group>

            {/* Remember me */}
            {/* <Form.Group className="pb-4 font_size_14 fw_600">
              <Form.Check
                type="checkbox"
                required
                label="By registering, you agree to our Terms of Service and Terms of Service and Privacy Policy."
              />
            </Form.Group> */}

            {/* Sign up button */}
            <Button type="submit" className="w-100 login_btn mb-3">
              Sign Up
            </Button>

            {/* Sign in button */}
            <p className="text-center font_size_14 fw_600 link_tag">
              Already a Member ?{" "}
              <Link to="/login" className="cl_gold">
                Sign In
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
