import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const ManageBlog = () => {
  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/blog`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };

  //status update
  const statusUpdate = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/categories/status-update/${id}`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        renderAllInfo();
      });
  };
  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/blog/remove/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Blog management</h5>
                  <Link to="/admin/blogcategory">
                    <Button variant="success" size="sm">
                      View All Blog's Category &nbsp;
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header">
                  <Link to="/admin/blog/create">
                    <Button variant="success" size="sm">
                      Add New Blog &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div>
                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{ width: "" }}>
                          Image
                        </th>
                        <th scope="col" style={{ width: "300px" }}>
                          Title
                        </th>
                        <th scope="col" style={{ width: "200px" }}>
                          Category
                        </th>
                        {/* <th scope="col" style={{ width: "150px" }}>
                          Status
                        </th> */}
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>
                            <img
                              className="img-thumbnail"
                              style={{
                                width: "80px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                              src={`${fileUrl}${data?.image}`}
                              alt=""
                            />
                          </td>
                          <td>{data?.title}</td>
                          <td>{data?.blog_cat?.name}</td>

                          {/* status update */}
                          {/* <td>
                            <div className="">
                              <Form>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <option value="0">Disable</option>
                                  <option value="1">Enable</option>
                                </Form.Select>
                              </Form>
                            </div>
                          </td> */}

                          <td className="text-center">
                            {/* edit button */}
                            <Link to={`/admin/blog/edit/${data?.id}`}>
                              <button className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1">
                                <BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1em"
                                />
                              </button>
                            </Link>

                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBlog;
