import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";

const UsersOrder = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [ordered_items, setOrderItems] = useState([]);
  const renderOrderInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}user/orders`, {
        headers: {
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setOrderItems(res.data?.data);
      });
  };
  useEffect(() => {
    renderOrderInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>My Orders</h5>
                  {/* <Link to="/user/ticket/new">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Add New &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* jodi data pay */}
        {!isLoading && !error && ordered_items?.length > 0 && (
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <div className="table-responsive py-3">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Order ID</th>
                          <th scope="col">Total Amount</th>
                          {/* <th scope="col">Payment Status</th> */}
                          {/* <th scope="col">Order Status</th> */}
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordered_items?.map((data, i) => (
                          <tr key={data.id}>
                            <td> {i + 1}</td>
                            <td>{data?.orderId}</td>
                            <td>$ {data?.total_amount}</td>

                            {/* Actions  */}
                            <td>
                              {/* view button */}
                              <Link to={`/user/orders/${data?.id}`}>
                                <button className="py-1 px-2 bg-info border-0 rounded-2 me-1 mb-1">
                                  <FaEye
                                    style={{
                                      color: "white",
                                    }}
                                    title="view details"
                                    size="1em"
                                  />
                                </button>
                              </Link>

                              {/* delete button */}
                              {/* <button
                          onClick={() => deleteData(data.id)}
                          className="py-1 px-2 bg-danger border-0 rounded-2 me-1 mb-1"
                        >
                          <MdDeleteForever
                            style={{
                              color: "white",
                            }}
                            title="Delete"
                            size="1em"
                          />
                        </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading && !error && (
          <>
            <div className="d-flex justify-content-center pb-5 pt-5">
              <DotLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UsersOrder;
