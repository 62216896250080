import React, { useState } from "react";
import { Link, Outlet, NavLink, useNavigate } from "react-router-dom";

import AdminFooter from "./AdminFooter";
import "./AdminContent.css";
import axios from "axios";
import AdminDashboardNavbar from "../Admin/Dashboard/AdminDashboardNavbar";
import { Collapse } from "react-bootstrap";
import { MdDashboard } from "react-icons/md";
import { BiListUl, BiLogOut } from "react-icons/bi";
import { AiOutlineClear, AiOutlineMenu } from "react-icons/ai";
import getCookie from "../../Component/Cookie/Get.Cookie";
import { BsList } from "react-icons/bs";
import { BACKEND_BASE_URL } from "../../Utils/GlobalVariables";
import { ToastSuccess } from "../../Component/ToastAlert";
import { FiChevronDown } from "react-icons/fi";
import Cookie from "cookie-universal";

const AdminContent = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const cookies = Cookie();

  const cusToggole = (id) => {
    var colDiv = document.getElementById(id + "Collapse");
    if (colDiv.getAttribute("class", "show") == "collapse show ") {
      document.getElementById(id + "Collapse").classList.remove("show");
    } else {
      colDiv.setAttribute("class", "collapse show ");
    }
  };

  const navigate = useNavigate();

  const cacheclear = () => {
    axios
      .get(`${BACKEND_BASE_URL}admin/clear-cache`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
        }
      });
  };

  const logoutAdmin = () => {
    axios
      .get(`${BACKEND_BASE_URL}admin/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response?.data?.status == 200) {
          cookies.removeAll();
          ToastSuccess.fire({
            icon: "success",
            title: response?.data?.message,
          });
          // cookies.removeAll();
        }
        navigate("/admin/login");
      });
  };

  return (
    <div className="d-flex admin__dashboard">
      <nav className={`admin_sidebar ${toggleNav ? "close" : ""}`}>
        <header className="mt-4 mb-5">
          <Link to="/" target="_blank" className="text-decoration-none">
            <div className="mx-4 image-text d-none d-md-flex align-items-center justify-content-between">
              <div className="text logo_img_wrapper">Admin Dashboard</div>
            </div>
          </Link>
          <AiOutlineMenu
            className="bx bx-chevron-right toggle d-none d-md-block"
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links ps-0">
              <li
                title="Dashboard"
                className=" d-flex align-items-center cursor"
              >
                <NavLink to="/admin/" className="nav-link">
                  <div className="d-flex justify-content-between align-items-center">
                    <MdDashboard className="icon" />
                    <div className="text nav-text dropdown_menu py-1">
                      Dashboard
                    </div>
                  </div>
                </NavLink>
              </li>

              {/* Category management start  */}
              <li
                title="Category Management"
                className="nav-link d-flex align-items-center cursor"
                id="cat"
                onClick={() => cusToggole("cat")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage Categories
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="catCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="category">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Category
                      </p>
                    </NavLink>

                    <NavLink to="subcategory">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Sub Category
                      </p>
                    </NavLink>

                    <NavLink to="childcategory">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Child Category
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* Category management end  */}

              {/* product management start  */}
              <li
                title="Product Management"
                className="nav-link d-flex align-items-center cursor"
                id="product"
                onClick={() => cusToggole("product")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage Products
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="productCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="brand">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Brands
                      </p>
                    </NavLink>

                    <NavLink to="product">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Products
                      </p>
                    </NavLink>

                    <NavLink to="stock/out/product">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Stock Out Products
                      </p>
                    </NavLink>
                    <NavLink to="campaign">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Campaign Offer
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* product management end  */}

              {/* order management start  */}
              <li
                title="Manage Orders"
                className="nav-link d-flex align-items-center cursor"
                id="orders"
                onClick={() => cusToggole("orders")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage Orders
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="ordersCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="orders/type=all">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        All Orders
                      </p>
                    </NavLink>

                    <NavLink to="orders/type=Pending">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Pending Orders
                      </p>
                    </NavLink>

                    <NavLink to="orders/type=in-progress">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        In Progress Orders
                      </p>
                    </NavLink>
                    <NavLink to="orders/type=Delivered">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Delivered Orders
                      </p>
                    </NavLink>
                    <NavLink to="orders/type=Canceled">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Cancelled Orders
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* order management end  */}

              {/* Ecommerce start  */}
              <li
                title="Ecommerce"
                className="nav-link d-flex align-items-center cursor"
                id="ecommerce"
                onClick={() => cusToggole("ecommerce")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Ecommerce
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="ecommerceCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="coupon">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Set Coupons
                      </p>
                    </NavLink>

                    <NavLink to="shipping">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Shipping
                      </p>
                    </NavLink>

                    <NavLink to="tax">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Tax
                      </p>
                    </NavLink>
                    <NavLink to="currency">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Currency
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* Ecommerce end  */}

              {/* FAQs start  */}
              <li
                title="FAQ Management"
                className="nav-link d-flex align-items-center cursor"
                id="FAQs"
                onClick={() => cusToggole("FAQs")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage FAQs
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="FAQsCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="faqcategory">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Categories
                      </p>
                    </NavLink>

                    <NavLink to="faq">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        FAQs
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* FAQs end  */}

              {/* Manage Blogs start  */}
              <li
                title="Blog Management"
                className="nav-link d-flex align-items-center cursor"
                id="blog"
                onClick={() => cusToggole("blog")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage Blogs
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="blogCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="blogcategory">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Categories
                      </p>
                    </NavLink>

                    <NavLink to="blog">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Blogs
                      </p>
                    </NavLink>
                  </div>
                </Collapse>
              </div>
              {/* Manage Blogs end  */}

              {/* Manage site setting start  */}
              <li
                title="Manage site"
                className="nav-link d-flex align-items-center cursor"
                id="site_setting"
                onClick={() => cusToggole("site_setting")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiListUl className="icon" />
                  <div className="text nav-text dropdown_menu py-1">
                    Manage Site
                    <span>
                      <FiChevronDown className={`ms-4`} />
                    </span>
                  </div>
                </div>
              </li>
              <div className="dropdown_menu_item w-100">
                <Collapse id="site_settingCollapse" in={false}>
                  <div id="example-collapse-text">
                    <NavLink to="popular-categories">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Popular Categories
                      </p>
                    </NavLink>

                    <NavLink to="email/setting">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Email Settings
                      </p>
                    </NavLink>
                    <NavLink to="maintenance/setting">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" />
                        Maintenance
                      </p>
                    </NavLink>
                    {/* <NavLink to="general/setting">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" /> General Settings
                      </p>
                    </NavLink> */}
                    {/* <NavLink to="sms/setting">
                      <p className="text nav-text mb-0 py-2">
                        <BsList className="icon" /> SMS Settings
                      </p>
                    </NavLink> */}
                  </div>
                </Collapse>
              </div>
              {/* Manage site setting end  */}

              <li
                title="Manage Tickets"
                className=" d-flex align-items-center cursor"
              >
                <NavLink to="ticket" className="nav-link">
                  <div className="d-flex justify-content-between align-items-center">
                    <BiListUl className="icon" />
                    <div className="text nav-text dropdown_menu py-1">
                      Manage Tickets
                    </div>
                  </div>
                </NavLink>
              </li>

              <li
                title="Customers List"
                className=" d-flex align-items-center cursor"
              >
                <NavLink to="customers" className="nav-link">
                  <div className="d-flex justify-content-between align-items-center">
                    <BiListUl className="icon" />
                    <div className="text nav-text dropdown_menu py-1">
                      Customers List
                    </div>
                  </div>
                </NavLink>
              </li>

              <li
                title="Subscribers List"
                className=" d-flex align-items-center cursor"
              >
                <NavLink to="subscribers" className="nav-link">
                  <div className="d-flex justify-content-between align-items-center">
                    <BiListUl className="icon" />
                    <div className="text nav-text dropdown_menu py-1">
                      Subscribers List
                    </div>
                  </div>
                </NavLink>
              </li>

              {/* <li
                title="Email Setting"
                className=" d-flex align-items-center cursor"
              >
                <NavLink to="email/setting" className="nav-link">
                  <div className="d-flex justify-content-between align-items-center">
                    <BiListUl className="icon" />
                    <div className="text nav-text dropdown_menu py-1">
                      Email Settings
                    </div>
                  </div>
                </NavLink>
              </li> */}

              <li className="nav-link">
                <Link to="" onClick={cacheclear}>
                  <AiOutlineClear className="icon" />
                  <span className="text nav-text ">Cache Clear</span>
                </Link>
              </li>

              <li className="nav-link ">
                <Link to="" className="user_logout" onClick={logoutAdmin}>
                  <BiLogOut className="icon" />
                  <span className="text nav-text text-uppercase">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="rightbar">
        {/* <div className="topbar" /> */}
        <section style={{ marginBottom: "100px" }}>
          <AdminDashboardNavbar />
          <Outlet />
        </section>
      </section>
      <AdminFooter />
    </div>
  );
};

export default AdminContent;
