import React from "react";
import { Form } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhoneIphone } from "react-icons/md";

const NotificationSettings = () => {
  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Notification settings</h1>

      <div className="notification_settings">
        <div className="d-lg-flex justify-content-lg-between align-items-lg-end">
          <h4 className="mb-4 mb-lg-0">Services Recommendations</h4>
          <div className="d-flex justify-content-lg-end">
            <div className="d-flex justify-content-center align-items-center flex-column me-4">
              <AiOutlineMail size="1.5rem" />
              <span className="my-2">EMAIL</span>
              <Form.Check type="checkbox" id="custom-switch" />
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <MdPhoneIphone size="1.5rem" />
              <span className="my-2">PUSH</span>
              <Form.Check type="checkbox" id="custom-switch" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
