/*===================
    Footer 
=====================
1. variable 
2. main function

  a. footer_top_subscriber
  b. footer_mid
  c. footer_end

================*/

import React, { useEffect, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import { GiRolledCloth } from "react-icons/gi";
import { AiOutlineArrowRight } from "react-icons/ai";
import { RiLock2Line, RiStarSFill } from "react-icons/ri";
import { BsBoxSeam, BsFillTelephoneFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import { Link } from "react-router-dom";
import footer_logo from "../../../Assets/footer_logo.png";
import "./Footer.css";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import axios from "axios";
import { ToastSuccess } from "../../ToastAlert";

const Footer = () => {
  const email = useRef();
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("email", email.current.value);

    axios
      .post(`${BACKEND_BASE_URL}subscribe`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.errors?.email,
          });
        }
      });
    e.preventDefault();
  };
  useEffect(() => {}, []);
  return (
    <div>
      {/* footer_top_subscriber */}
      <div className=" footer_top_subscriber">
        <div className="custom_container">
          <Row className="justify-content-center align-items-center">
            <Col
              xxl={4}
              xl={5}
              md={6}
              sm={10}
              className="mb-4 mb-md-0 d-flex flex-md-nowrap flex-wrap  justify-content-md-start justify-content-center text-center text-md-start align-items-center "
            >
              <FiMail size={100} className="cl_gold me-2" />
              <p className="cl_white mb-0 font_size_25 fw_700 mb-0">
                Subscribe to the newsletter
              </p>
            </Col>
            <Col xxl={4} xl={5} md={6} sm={10}>
              <Form>
                <InputGroup>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email Address"
                    ref={email}
                    id=""
                  />
                  <InputGroup.Text
                    className="bg_lightGold px-4 fw_700"
                    onClick={storeData}
                  >
                    OK <AiOutlineArrowRight size={20} className=" ms-2" />
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      {/* footer_mid */}
      {/* <div className="footer_mid sesction_pt sesction_pb bg_lightDark2">
        <div className="custom_container">
          <Row className="mt-4">
            <Col lg={3} sm={6} className="text-center mb-4">
              <GiRolledCloth size={65} className="cl_gold mb-3" />
              <p className="cl_white font_size_21 fw_700 mb-0">
                Selected cloths
              </p>
              <small className=" font_size_16 cl_lightGrey0">
                from around the world.
              </small>
            </Col>
            <Col lg={3} sm={6} className="text-center mb-4">
              <TbTruckDelivery size={65} className="cl_gold mb-3" />
              <p className="cl_white font_size_21 fw_700 mb-0">
                Safe shipping globally
              </p>
              <small className=" font_size_16 cl_lightGrey0">
                by professionals.
              </small>
            </Col>
            <Col lg={3} sm={6} className="text-center mb-4">
              <BsBoxSeam size={65} className="cl_gold mb-3" />
              <p className="cl_white font_size_21 fw_700 mb-0">Free returns</p>
              <small className=" font_size_16 cl_lightGrey0">
                within 14 days after delivery.
              </small>
            </Col>
            <Col lg={3} sm={6} className="text-center mb-4">
              <RiLock2Line size={65} className="cl_gold mb-3" />
              <p className="cl_white font_size_21 fw_700 mb-0">
                Secure payments
              </p>
              <small className=" font_size_16 cl_lightGrey0">
                by credit card or bank transfer.
              </small>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* footer_end */}
      <div className="footer_end sesction_pt sesction_pb bg_Dark">
        <div className="custom_container">
          <Row>
            <Col xs={6} lg={3} className="text-center d-lg-block d-none">
              <Link to="">
                <img className="footer_logo" src={footer_logo} alt="" />
              </Link>
            </Col>
            <Col xs={6} lg={3}>
              <div className="category">
                <p className="fw_700 cl_white font_size_21">Customer Service</p>
                <ul className="sub_category px-0 l_s_none">
                  <li>
                    <Link to="">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="">Legal Notices</Link>
                  </li>
                  <li>
                    <Link to="">General Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="">Customer Testimonials</Link>
                  </li>
                  <li>
                    <Link to="">Offer a Gift Card</Link>
                  </li>
                  <li>
                    <Link to="">Coupons</Link>
                  </li>
                  <li>
                    <Link to="">Festival Offers</Link>
                  </li>
                  <li>
                    <Link to="">My Profile</Link>
                  </li>
                  <li>
                    <Link to="">Dress for Women</Link>
                  </li>
                  <li>
                    <Link to="">Dress for Men</Link>
                  </li>
                  <li>
                    <a href="tel:+1 646-844-3541">
                      <BsFillTelephoneFill size={16} /> +0 123-456-789
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} lg={3}>
              <div className="category">
                <p className="fw_700 cl_white font_size_21">Who Are We?</p>
                <ul className="sub_category px-0 l_s_none">
                  <li>
                    <Link to="">About Us</Link>
                  </li>
                  <li>
                    <Link to="">Festivals</Link>
                  </li>
                  <li>
                    <Link to="">Fashion Magazines</Link>
                  </li>
                  <li>
                    <Link to="">The Team</Link>
                  </li>
                  <li>
                    <Link to="">Our Selection Criteria</Link>
                  </li>
                  <li>
                    <Link to="">FAQ</Link>
                  </li>
                  <li>
                    <Link to="">Jobs</Link>
                  </li>
                  <li>
                    <Link to="">Contact</Link>
                  </li>
                  <li>
                    <Link to="">Impact Report 2022</Link>
                  </li>
                  <li>
                    <Link to="">Fashion Topics</Link>
                  </li>
                </ul>
                <p className="fw_700 cl_white font_size_21">Press</p>
                <ul className="sub_category px-0 l_s_none">
                  <li>
                    <Link to="">Contact</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} lg={3}>
              <div className="category">
                <p className="fw_700 cl_white font_size_21">
                  Are you a Seller?
                </p>
                <ul className="sub_category px-0 l_s_none">
                  <li>
                    <Link to="">Join FANTABULOUS as an Seller</Link>
                  </li>
                  <li>
                    <Link to="">Join FANTABULOUS as a Designer</Link>
                  </li>
                  <li>
                    <Link to="">Log-in for Seller</Link>
                  </li>
                  <li>
                    <Link to="">Explore All Collections</Link>
                  </li>
                </ul>
                <p className="fw_700 cl_white font_size_14">
                  Designer in Focus:
                </p>
                <ul className="sub_category px-0 l_s_none">
                  <li>
                    <Link to="">John Doe</Link>
                  </li>
                  <li>
                    <Link to="">Joe Blow</Link>
                  </li>
                  <li>
                    <Link to="">Mr Brown</Link>
                  </li>
                  <li>
                    <Link to="">John Smith</Link>
                  </li>
                  <li>
                    <Link to="">Joe Sixpack</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="align-items-center">
            <Col lg={9} className="review">
              <small className="cl_white font_size_12 d-flex flex-wrap text-center align-items-center justify-content-lg-start justify-content-center">
                FANTABULOUS has received a rating of 4.9/5 based on 3166
                reviews.
                <div className="rating d-flex">
                  <RiStarSFill size={25} className="cl_gold" />
                  <RiStarSFill size={25} className="cl_gold" />
                  <RiStarSFill size={25} className="cl_gold" />
                  <RiStarSFill size={25} className="cl_gold" />
                  <RiStarSFill size={25} className="cl_gold" />
                </div>
              </small>
            </Col>
            <Col lg={3}>
              <div className="cl_white font_size_12 text-lg-end text-center mt-4 mt-lg-0">
                © 2023 Fantabulous
              </div>
            </Col>
            <Col xs={3} md={2} className="text-center d-lg-none mx-auto mt-4">
              <Link to="">
                <img className="w-100" src={footer_logo} alt="" />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
