import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const ChildCategoryAdd = () => {
  const name = useRef();
  const category_id = useRef();
  const sub_category_id = useRef();
  const image = useRef();

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial add info
  const [addInfo, setAddInfo] = useState([]);
  // Get add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/child-categories/store/info`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setAddInfo(res.data?.data);
      });
  };

  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const SubCat = (e) => {
    for (let i = 0; i < addInfo?.length; i++) {
      if (addInfo[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo[i]);
      }
    }
  };

  //   console.log("add", subCategoryValue);

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("name", name.current.value);
    formdata.append("category_id", category_id.current.value);
    {
      subCategoryValue?.sub_category?.length > 0 &&
        formdata.append("sub_category_id", sub_category_id.current.value);
    }
    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }

    axios
      .post(`${BACKEND_BASE_URL}admin/child-categories/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status == "ok") {
          ToastSuccess.fire({
            icon: "success",
            // title: response.data?.message,
            title: "Child category added...!",
          });
          e.target.reset();
          category_id.current.value = "";
          setFile([]);
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Add New Child Category</div>
                  <Link to="/admin/childcategory">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add name */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Name&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="name"
                              ref={name}
                            />
                          </Form.Group>

                          {/* Category */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Category&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={category_id}
                              onChange={SubCat}
                            >
                              <option value="" selected>
                                Select Category
                              </option>
                              {addInfo?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Sub Category */}
                          {subCategoryValue?.sub_category?.length > 0 && (
                            <Form.Group
                              as={Col}
                              md="3"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Sub Category&nbsp;{""}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={sub_category_id}
                              >
                                <option value="">Select Sub Category</option>
                                {subCategoryValue?.sub_category?.map((data) => (
                                  <option value={data?.id} key={data.id}>
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          )}

                          {/* image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Image&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={image}
                              onChange={handleImgPreview}
                            />

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildCategoryAdd;
