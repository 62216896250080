/*===================
    forget password  INDEX  
=====================
1. variable 
2. main function

  a. notification function
  b. Form submit function
  c. notification
  d. Email
  e. Email sent button

================*/

// variable

import React, { useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import "./Login.css";
import logo_long from "../../Assets/login_logo.png";

const ForgetPass = () => {
  // notification function
  const [show, setShow] = useState(false);

  // Form submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    setShow(true);
  };

  return (
    <div className=" logIn d-flex justify-content-center align-items-center ">
      {/* notification */}

      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg="success"
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header className="rounded-0 border-0 ">
            <strong className="me-auto">Email Sent Successfully</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>

      <div className="custom_container">
        <div className="logInForm">
          <div className="w-100 text-center mb-5">
            <img src={logo_long} alt="Themepoints" className="img-fluid " />
          </div>
          <Form className="form" onSubmit={handleSubmit}>
            {/* Email */}

            <Form.Group className="form_group pb-3">
              <Form.Label className="text-center font_size_12 fw_700">
                Enter your email to recover your account.
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter email *"
                name="email"
                required
              />
            </Form.Group>

            {/* Email sent button */}
            <Button type="submit" className="w-100 login_btn mb-3">
              Email me a recovery link
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;
