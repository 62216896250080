/*===================
    Product details  INDEX  
=====================
1. variable 
2. slider setting arrow design

  a. right arrow design
  b. left arrow design

3. main function home()

  a. slider setting
  b. dynamically title and meta tag for seo
  c. header 
  d. homeProductDetails
  e. footer 

================*/

// variable

import React, { useEffect, useState } from "react";
import Footer from "../../Component/Common/Footer/Footer";
import Header from "../../Component/Common/Header/Header";
// import "./HomeProductDetails.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BACKEND_BASE_URL, fileUrl } from "../../Utils/GlobalVariables";
import axios from "axios";
import { DotLoader } from "../../Component/Custom Loader/CustomLoader";
import "./AllCategory.css";
import { Pagination } from "@mui/material";

// main function
const AllCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [page, setPage] = useState(1);
  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);

  // Initial Home data
  const [categoryInfo, setCategoryInfo] = useState([]);
  // Get All Info
  const renderBlogInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}categories?page=${page}`)
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setCategoryInfo(res?.data?.data?.data);

        setFirstPage(res.data?.data?.from);
        setLastPage(res.data?.data?.last_page);
      });
  };
  //   console.log("vum", blogData);

  useEffect(() => {
    window.scrollTo(0, 0);
    renderBlogInfo();
    // renderCategory();
  }, [page]);
  return (
    <>
      {/* header */}
      <Header />
      {/*======== dynamically title and meta tag for seo start ========*/}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fantabulous | Product Details</title>
      </Helmet>
      <div className="all_categories">
        <div className="sesction_pt  sesction_pb">
          <div className="custom_container ">
            {!isLoading && !error && categoryInfo?.length > 0 && (
              <>
                {categoryInfo.length > 0 && (
                  <h2 className="ms-0 font_size_25">Categories</h2>
                )}
                <div className="grid font_size_16 text-center">
                  {categoryInfo?.length > 0 &&
                    categoryInfo?.map((data, i) => (
                      <div className="image" key={data?.id}>
                        <Link to={`/products/${data?.slug}`}>
                          <img
                            style={{
                              height: "225px",
                              width: "225px",
                              objectFit: "cover",
                            }}
                            src={`${fileUrl}${data?.image}`}
                            alt=""
                          />
                          <h3 className="font_size_16">{data?.name}</h3>
                        </Link>
                      </div>
                    ))}
                </div>
              </>
            )}
            {/* jodi data na pay */}
            {isLoading && !error && (
              <>
                <div className="d-flex justify-content-center pb-5">
                  {/* <PulseLoader /> */}
                  <DotLoader />
                </div>
              </>
            )}

            {!isLoading && !error && categoryInfo?.length < 1 && (
              <>
                <div className="text-center text-danger pb-5">
                  <h1>No data Found</h1>
                </div>
              </>
            )}
          </div>

          {/* paginate */}
          {!isLoading && !error && categoryInfo?.length > 12 && (
            <div className="pagination_box d-flex justify-content-center mt-5">
              <Pagination
                className="paggination  py-3 px-5 flex-wrap"
                count={lastPage}
                defaultPage={page}
                selected={page}
                siblingCount={1}
                onChange={(e, value) => setPage(value)}
              />
            </div>
          )}
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  );
};

export default AllCategory;
