import axios from "axios";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import { PassInputFieldFormControl } from "../../../Component/PassInputField";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const UserPassChange = () => {
  const current_password = useRef();
  const new_password = useRef();
  const confirm_password = useRef();

  const [error, setError] = useState([]);

  const storeData = (e) => {
    const formdata = new FormData();

    if (current_password.current.value) {
      formdata.append("current_password", current_password.current.value);
      formdata.append("new_password", new_password.current.value);
      formdata.append("confirm_password", confirm_password.current.value);
    }

    axios
      .post(`${BACKEND_BASE_URL}user/change-password`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
        } else if (response.data?.status == 401) {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        } else {
          setError(response.data?.errors);
        }
      });
    e.preventDefault();
  };

  return (
    <div className="user_dashboard_content">
      {/* <h1 className="mb-5 ms-3 ms-lg-0">Change password</h1> */}
      <div className="main__container">
        <div className="body-wrapper">
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <span className="top-border"></span>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Change password</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <div className="edit_password">
                    <Form onSubmit={storeData} autoComplete="off">
                      <Form.Group className="user_dashboard_content mb-4">
                        <Form.Label className="mb-2">
                          Current Password:{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <PassInputFieldFormControl
                          inputRef={current_password}
                          placeholderValue="Current Password"
                          eyeIconStyle="mb-1"
                        />
                        <div className="mb-2">
                          <small className="text-danger">
                            {error?.current_password}
                          </small>
                        </div>
                      </Form.Group>

                      <Form.Group className="user_dashboard_content mb-4">
                        <Form.Label className="mb-2">
                          New Password: <span className="text-danger">*</span>
                        </Form.Label>
                        <PassInputFieldFormControl
                          inputRef={new_password}
                          placeholderValue="New Password"
                          className=""
                          eyeIconStyle="mb-1"
                        />
                        <div className="mb-2">
                          <small className="text-danger">
                            {error?.new_password}
                          </small>
                        </div>
                      </Form.Group>

                      <Form.Group className="user_dashboard_content mb-4">
                        <Form.Label className="mb-2">
                          Confirm Password:{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>

                        <PassInputFieldFormControl
                          inputRef={confirm_password}
                          placeholderValue="Confirm Password"
                          eyeIconStyle="mb-1"
                        />

                        <div className="mb-2">
                          <small className="text-danger">
                            {error?.confirm_password}
                          </small>
                        </div>
                      </Form.Group>

                      <div className="save_profile_btn mt-4 mb-5 d-flex justify-content-center justify-content-lg-end">
                        {/* <button className="me-3">Cancel</button> */}
                        {/* <button onClick={() => {}}>Set new Password</button> */}
                        <button type="submit">Set new Password</button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPassChange;
