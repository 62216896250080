import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../../Utils/GlobalVariables";
import getCookie from "../../../../Component/Cookie/Get.Cookie";
import { FaEye } from "react-icons/fa";
import { DotLoader } from "../../../../Component/Custom Loader/CustomLoader";

const SupportTicket = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}user/support/ticket`, {
        headers: {
          Authorization: `Bearer ${getCookie("user_access_token")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.info);
        setIsLoading(false);
        setError(false);
      });
  };
  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>All Tickets</h5>
                  <Link to="/user/ticket/new">
                    <Button style={{ background: "#0289ff" }} size="sm">
                      Add New &nbsp;
                      <span>
                        <AiOutlinePlusCircle />
                      </span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* jodi data pay */}
        {!isLoading && !error && tableData?.length > 0 && (
          <div className="col-md-12 p-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <div className="table-responsive py-3">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" style={{ width: "250px" }}>
                            Subject
                          </th>
                          <th scope="col">Last Reply</th>
                          <th scope="col" style={{ width: "200px" }}>
                            Status
                          </th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, i) => (
                          <tr key={data.id}>
                            <td> {i + 1}</td>
                            <td>{data?.subject}</td>
                            <td>
                              {data?.last_reply != null && (
                                <p>{data?.last_reply?.reply_message}</p>
                              )}
                            </td>
                            {/* status update */}

                            <td>
                              {data?.status == 0 ? (
                                <p>Pending Ticket</p>
                              ) : data?.status == 1 ? (
                                <p>Open Ticket</p>
                              ) : data?.status == 2 ? (
                                <p>Closed Ticket</p>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="text-center">
                              {/* view button */}
                              <Link to={`/user/ticket/${data?.id}`}>
                                <button className="py-1 px-2 bg-info border-0 rounded-2 me-1 mb-1">
                                  <FaEye
                                    style={{
                                      color: "view",
                                    }}
                                    title="ticket"
                                    size="1em"
                                  />
                                </button>
                              </Link>

                              {/* delete button */}
                              <button className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1">
                                <MdDeleteForever
                                  style={{
                                    color: "white",
                                  }}
                                  title="Delete"
                                  size="1em"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLoading && !error && (
          <>
            <div className="d-flex justify-content-center pb-5 pt-5">
              <DotLoader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SupportTicket;
