import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import RichTextEditor from "../../../Component/RichTextEditor";
// import "./Keywords.css";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";

const TaxAdd = () => {
  const title = useRef();
  const price = useRef();

  const [titleError, setTitleErrorMessage] = useState("");
  const [priceError, setPriceErrorMessage] = useState("");
  // form submit to backend
  const storeData = (e) => {
    // console.log("hi");
    // console.log("Name: " + name.current.value);
    const formdata = new FormData();

    formdata.append("title", title.current.value);
    formdata.append("price", price.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin/taxes/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response.data?.status === "ok") {
          ToastSuccess.fire({
            icon: "success",
            // title: response.data?.message,
            title: "Tax added...!",
          });
          e.target.reset();
        } else {
          setTitleErrorMessage(response?.data?.errors?.title);
          setPriceErrorMessage(response?.data?.errors?.price);
        }
      });
    e.preventDefault();
  };

  useEffect(() => {}, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Create Tax</div>
                  <Link to="/admin/tax">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add name */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Title&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="title"
                              ref={title}
                            />
                          </Form.Group>
                          <p className="text-danger">{titleError}</p>

                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Price&nbsp;{""}
                              <span className="text-danger">*</span>
                              <span> (Set 0 to make it free)</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Enter value (%)"
                              ref={price}
                            />
                          </Form.Group>
                          <p className="text-danger">{priceError}</p>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaxAdd;
