import React from "react";
// import UserContent from "./UserContent";
import "./User.css";
// import UserProfile from "./UserProfile";
import UserProfile2 from "./User Profile Dashboard/UserProfile";

const User = () => {
  return (
    <div className="position-relative">
      {/* <UserContent /> */}
      <UserProfile2 />
    </div>
  );
};

export default User;
