import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL, fileUrl } from "../../../Utils/GlobalVariables";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import { FiSave } from "react-icons/fi";
import { Autocomplete, TextField } from "@mui/material";

function CampaignOffer() {
  const title = useRef();
  const start_date = useRef();
  const last_date = useRef();
  const status = useRef();
  const image = useRef();

  const product_id = useRef();

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Initial table data
  const [tableData, setTableData] = useState([]);
  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/campaign/products/list`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.data);
      });
  };
  console.log("tableData", tableData);

  // product data for add to campaign
  const [aLLProductList, setALLProductList] = useState([]);
  const renderAllProductInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/products`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setALLProductList(res.data?.data);
      });
  };

  // Initial table data
  const [campaignInfo, setCampaignInfo] = useState([]);
  // Get Single Info
  const renderCampaignInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/campaign/${1}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setCampaignInfo(res.data?.campaignInfo);
      });
  };

  // form submit to backend
  // const storeData = (e) => {
  //   const formdata = new FormData();

  //   formdata.append("title", title.current.value);
  //   formdata.append("start_date", start_date.current.value);
  //   formdata.append("last_date", last_date.current.value);
  //   formdata.append("image", image.current.files[0]);
  //   axios
  //     .post(`${BACKEND_BASE_URL}admin/campaign/store`, formdata, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${getCookie("admin_access_token")}`,
  //       },
  //     })

  //     .then((response) => {
  //       if (response.data?.status === "ok") {
  //         ToastSuccess.fire({
  //           icon: "success",
  //           title: "campaign saved...!",
  //         });
  //         e.target.reset();
  //         setFile([]);
  //       }
  //     });
  //   e.preventDefault();
  // };

  // Updated data to backend
  const saveCampaignData = (e) => {
    const formdata = new FormData();
    // formdata.append("_method", "PUT");
    formdata.append("_method", "PATCH");

    formdata.append("title", title.current.value);
    formdata.append("start_date", start_date.current.value);
    formdata.append("last_date", last_date.current.value);

    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    formdata.append("status", status.current.value);

    axios
      .post(`${BACKEND_BASE_URL}admin/campaign/update/${1}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        if (response.data?.status === "ok") {
          ToastSuccess.fire({
            icon: "success",
            // title: response.data?.message,
            title: "Save successfully...!",
          });
          e.target.reset();
          setFile([]);
          renderCampaignInfo();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        }
      });
    e.preventDefault();
  };

  const [value, setValue] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [name, setName] = React.useState(null);
  // console.log("id", id);
  // console.log("name", name);

  // form submit to backend
  const productAddToCampaign = (e) => {
    const formdata = new FormData();

    formdata.append("product_id", id);
    formdata.append("campaign_id", 1);

    axios
      .post(
        `${BACKEND_BASE_URL}admin/campaign/products/add/to/campaign`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status == 200) {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });
          e.target.reset();
          setFile([]);
        } else {
          ToastSuccess.fire({
            icon: "warning",
            title: response.data?.message,
          });
        }
      });
    e.preventDefault();
  };

  // const showHomeActiveDeactive = (e) => {

  // }

  //status update
  const showHomeActiveDeactive = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}admin/campaign/products/status-update/${id}`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((response) => {
        ToastSuccess.fire({
          icon: "success",
          title: response.data?.message,
        });
        renderAllInfo();
      });
  };
  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}admin/campaign/products/remove/${id}`, {
          headers: {
            Authorization: `Bearer ${getCookie("admin_access_token")}`,
          },
        })
        .then((response) => {
          if (response.data?.status == 200) {
            ToastSuccess.fire({
              icon: "success",
              title: response.data?.message,
            });
            renderAllInfo();
          } else {
            ToastSuccess.fire({
              icon: "warning",
              title: response.data?.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
    renderAllProductInfo();
    renderCampaignInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        {/* <AdminDashboardNavbar /> */}

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <h5>Campaign Offer</h5>
              </div>
            </div>
          </div>
        </div>

        {/* campaign add section  */}
        <div className="col-md-12 ps-4 pe-4 pb-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <Form onSubmit={saveCampaignData}>
                  <div className="content-wrapper">
                    <div className="row py-1">
                      {/* add title */}
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Campaign Title&nbsp;{""}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="title"
                          ref={title}
                          defaultValue={campaignInfo?.title}
                        />
                      </Form.Group>
                      {/* start date  */}
                      <Form.Group
                        as={Col}
                        md="2"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Start Date&nbsp;{""}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          ref={start_date}
                          defaultValue={campaignInfo?.start_date}
                        />
                      </Form.Group>

                      {/* end date  */}
                      <Form.Group
                        as={Col}
                        md="2"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          End Date&nbsp;{""}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          ref={last_date}
                          defaultValue={campaignInfo?.last_date}
                        />
                      </Form.Group>

                      {/* status */}
                      <Form.Group
                        as={Col}
                        md="2"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Status&nbsp;{""}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          ref={status}
                        >
                          <option
                            value="0"
                            selected={
                              campaignInfo?.status == 1 ? "selected" : ""
                            }
                          >
                            unpublish
                          </option>

                          <option
                            value="1"
                            selected={
                              campaignInfo?.status == 0 ? "selected" : ""
                            }
                          >
                            publish
                          </option>
                        </Form.Select>
                      </Form.Group>

                      {/* image */}
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Image&nbsp;{""}
                          <span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          type="file"
                          ref={image}
                          onChange={handleImgPreview}
                        />

                        {files.map((file, key) => {
                          return (
                            <div key={key} className="Row">
                              <span className="Filename">
                                <img
                                  width={80}
                                  height={50}
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                />
                              </span>
                            </div>
                          );
                        })}
                        {files.length == 0 &&
                          (campaignInfo?.image != null ? (
                            <img
                              className="img-thumbnail mt-1"
                              width={80}
                              height={50}
                              src={`${fileUrl}${campaignInfo?.image}`}
                              alt=""
                              name="img"
                            />
                          ) : (
                            ""
                          ))}

                        <Form.Control.Feedback type="invalid">
                          Please choose an image
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div>
                        <Button
                          type="submit"
                          className="btn-submit mt-1 rounded-3 border-0"
                          style={{
                            width: "12%",
                          }}
                        >
                          <FiSave /> &nbsp; Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* product add for Campaign */}
        <div className="col-md-12 ps-4 pe-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <Form onSubmit={productAddToCampaign}>
                  <div className="content-wrapper">
                    <div className="row py-1">
                      <div className="col-6 mb-2">
                        <div className="label fw-bold">
                          Product Added for Campaign
                        </div>

                        <Autocomplete
                          id="free-solo-demo"
                          options={aLLProductList}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          onChange={(event, newValue) => {
                            // console.log(newValue);
                            if (newValue) {
                              setValue(newValue);
                              setId(newValue.id);
                              setName(newValue.name);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                border: "1px solid #86b7fe",
                              },
                            },
                            "& .MuiInputBase-root": {
                              ".MuiInputBase-root:hover": {
                                border: "none",
                              },
                              padding: "0",
                            },
                          }}
                        />
                        {/* <Autocomplete
                          id="free-solo-demo"
                          options={aLLProductList.map((option) => option.name)}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={product_id} />
                          )}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                border: "1px solid #86b7fe",
                              },
                            },
                            "& .MuiInputBase-root": {
                              ".MuiInputBase-root:hover": {
                                border: "none",
                              },
                              padding: "0",
                            },
                          }}
                        /> */}
                      </div>
                      <div>
                        <Button
                          type="submit"
                          className="btn-submit mt-1 rounded-3 border-0"
                          style={{
                            width: "20%",
                          }}
                        >
                          <FiSave /> &nbsp; Add to campaign
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* table  */}
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                {/* <div className="card-header">
              <Link to="/admin/category/add">
                <Button variant="success" size="sm">
                  Add New &nbsp;
                  <span>
                    <AiOutlinePlusCircle />
                  </span>
                </Button>
              </Link>
            </div> */}

                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-center">
                          Image
                        </th>
                        <th scope="col" className="text-center">
                          Show Home Page
                        </th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, i) => (
                        <tr key={data.id}>
                          <td> {i + 1}</td>
                          <td>{data?.products?.name}</td>
                          <td className="text-center">
                            <img
                              className="img-thumbnail"
                              style={{
                                width: "80px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                              src={`${fileUrl}${data?.products?.featured_image}`}
                              alt=""
                            />
                          </td>

                          {/* status update */}
                          <td>
                            <div className="d-flex justify-content-center">
                              <Form>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={() =>
                                    showHomeActiveDeactive(data.id)
                                  }
                                >
                                  <option
                                    value="0"
                                    selected={
                                      data?.show_home_page == 0
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    Inactive
                                  </option>
                                  <option
                                    value="1"
                                    selected={
                                      data?.show_home_page == 1
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    Active
                                  </option>
                                </Form.Select>
                              </Form>
                            </div>
                          </td>

                          <td className="text-center">
                            {/* delete button */}
                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1.5em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignOffer;
