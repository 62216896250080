import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Utils/GlobalVariables";
import { FiSave } from "react-icons/fi";
import { ToastSuccess } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/Cookie/Get.Cookie";
import RichTextEditor from "../../../Component/RichTextEditor";
import "./productTags.css";

const ProductAdd = () => {
  const name = useRef();
  const current_price = useRef();
  const previous_price = useRef();

  const category_id = useRef();
  const sub_category_id = useRef();
  const child_category_id = useRef();

  const featured_image = useRef();
  const gallery_image = useRef();

  const brand_id = useRef();
  const tax_id = useRef();

  const short_description = useRef();
  const long_description = useRef();
  const products_tag = useRef();

  const total_in_stock = useRef();
  const sku = useRef();
  const video_link = useRef();

  const meta_keywords = useRef();
  const meta_description = useRef();

  //long description
  const [long_description_value, set_long_description_value] = useState("");
  const get_long_description_value = (value) => {
    set_long_description_value(value);
  };

  //Featured Image Preview
  const [files, setFile] = useState([]);
  const handleFeaturedImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // Gallery Image Preview
  const [files2, setFile2] = useState([]);
  const handleGalleryImgPreview = (e) => {
    let allfiles2 = [];
    console.log("allfiles2", allfiles2);
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles2.push(e.target.files[i]);
    }
    if (allfiles2.length > 0) {
      setFile2(allfiles2);
    }
  };

  //product tag multi
  const [product_tags, setproduct_tags] = useState([]);
  const ProductTagInput = ({ limit, product_tags, setproduct_tags }) => {
    const [newProductTag, setNewProductTag] = React.useState("");
    const handleChange = (e) => {
      if (e.target.value !== ",") {
        setNewProductTag(e.target.value);
      }
    };

    const handleKeyDown = (e) => {
      if (e.keyCode == 13) {
        setproduct_tags([...product_tags, newProductTag]);
        setNewProductTag("");
      }
    };
    const handleDelete = (e) => {
      let indexToDelete = parseInt(e.target.id);
      setproduct_tags([
        ...product_tags.slice(0, indexToDelete),
        ...product_tags.slice(indexToDelete + 1),
      ]);
    };
    return (
      <div className="ProductTagInput">
        <div className="product_tags">
          {product_tags.map((tag, index) => (
            <div className="product_tag" key={index}>
              <span>{tag}</span>
              <button className="Delete" onClick={handleDelete} id={index}>
                ✕
              </button>
            </div>
          ))}
          <input
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={newProductTag}
            disabled={product_tags.length == limit ? true : false}
            placeholder="Separate product tags by pressing Enter"
            ref={products_tag}
          />
        </div>
      </div>
    );
  };

  //meta keyword multi
  const [tags, setTags] = useState([]);
  const TagInput = ({ limit, tags, setTags }) => {
    const [newTag, setNewTag] = React.useState("");
    const handleChange = (e) => {
      if (e.target.value !== ",") {
        setNewTag(e.target.value);
      }
    };

    const handleKeyDown = (e) => {
      if (e.keyCode == 13) {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    };
    const handleDelete = (e) => {
      let indexToDelete = parseInt(e.target.id);
      setTags([
        ...tags.slice(0, indexToDelete),
        ...tags.slice(indexToDelete + 1),
      ]);
    };
    return (
      <div className="TagInput">
        <div className="Tags">
          {tags.map((tag, index) => (
            <div className="Tag" key={index}>
              <span>{tag}</span>
              <button className="Delete" onClick={handleDelete} id={index}>
                ✕
              </button>
            </div>
          ))}
          <input
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={newTag}
            disabled={tags.length == limit ? true : false}
            placeholder="Separate meta keywords by pressing Enter"
            ref={meta_keywords}
          />
        </div>
      </div>
    );
  };

  // Initial add info
  const [addInfo, setAddInfo] = useState([]);
  const [addInfoBrand, setAddInfoBrand] = useState([]);
  const [addInfoTax, setAddInfoTax] = useState([]);
  // Get add Info
  const renderAddInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}admin/products/store/info`, {
        headers: {
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })
      .then((res) => {
        setAddInfo(res.data?.cat_subcat_childcat);
        setAddInfoBrand(res.data?.brand);
        setAddInfoTax(res.data?.tax);
      });
  };

  // console.log("add info", addInfo);

  // subcat value
  const [subCategoryValue, setSubcategoryValue] = useState([]);
  const SubCat = (e) => {
    for (let i = 0; i < addInfo?.length; i++) {
      if (addInfo[i]?.id == e.target.value) {
        setSubcategoryValue(addInfo[i]);
      }
    }
  };
  // child cat value
  const [childCategoryValue, setChildcategoryValue] = useState([]);
  const ChildCat = (e) => {
    for (let i = 0; i < subCategoryValue?.sub_category?.length; i++) {
      if (subCategoryValue?.sub_category[i]?.id == e.target.value) {
        setChildcategoryValue(subCategoryValue?.sub_category[i]);
      }
    }
  };

  // console.log("sub cat value", subCategoryValue);
  // console.log("child", childCategoryValue);

  //   console.log("add", subCategoryValue);

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", name.current.value);
    formdata.append("current_price", current_price.current.value);
    formdata.append("previous_price", previous_price.current.value);

    formdata.append("category_id", category_id.current.value);
    {
      subCategoryValue?.sub_category?.length > 0 &&
        formdata.append("sub_category_id", sub_category_id.current.value);
    }
    {
      childCategoryValue?.child_category?.length > 0 &&
        formdata.append("child_category_id", child_category_id.current.value);
    }

    if (featured_image.current.files[0]) {
      formdata.append("featured_image", featured_image.current.files[0]);
    }

    formdata.append("brand_id", brand_id.current.value);
    formdata.append("tax_id", tax_id.current.value);

    formdata.append("short_description", short_description.current.value);
    formdata.append("long_description", long_description.current.value);
    formdata.append("products_tag[]", product_tags);

    formdata.append("total_in_stock", total_in_stock.current.value);
    formdata.append("sku", sku.current.value);
    formdata.append("video_link", video_link.current.value);

    formdata.append("meta_description", meta_description.current.value);
    formdata.append("meta_keywords[]", tags);

    files2.forEach((item) => {
      formdata.append("gallery_image[]", item);
    });

    axios
      .post(`${BACKEND_BASE_URL}admin/products/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("admin_access_token")}`,
        },
      })

      .then((response) => {
        if (response.data?.status == "ok") {
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
            // title: "product added...!",
          });
          e.target.reset();
          setFile([]);
          setFile2([]);
          set_long_description_value("", "html");
          setproduct_tags([]);
          setTags([]);
          category_id.current.value = "";
          subCategoryValue.current.value = "";
          childCategoryValue.current.value = "";

          set_long_description_value("", "html");
          setproduct_tags([]);
          setTags([]);
        }
      });
    e.preventDefault();
  };
  useEffect(() => {
    renderAddInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-header">Add new product</div>
                  <Link to="/admin/product">
                    <Button variant="success" size="sm">
                      View All &nbsp;
                    </Button>
                  </Link>
                </div>

                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add name */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Name&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="name"
                              ref={name}
                            />
                          </Form.Group>

                          {/* current price  */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Current Price&nbsp;{""}
                              <span className="text-danger">$ *</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="current price"
                              ref={current_price}
                            />
                          </Form.Group>

                          {/* previous price  */}
                          <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Previous Price&nbsp;{""}
                              <span className="text-danger">$ *</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="previous price"
                              ref={previous_price}
                            />
                          </Form.Group>

                          {/* Category */}
                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Category&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={category_id}
                              onChange={SubCat}
                            >
                              <option value="" selected>
                                Select Category
                              </option>
                              {addInfo?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Sub Category */}
                          {subCategoryValue?.sub_category?.length > 0 && (
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Sub Category&nbsp;{""}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={sub_category_id}
                                onChange={ChildCat}
                              >
                                <option value="">Select Sub Category</option>
                                {subCategoryValue?.sub_category?.map((data) => (
                                  <option value={data?.id} key={data.id}>
                                    {data?.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          )}
                          {/* child Category */}
                          {childCategoryValue?.child_category?.length > 0 && (
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Child Category&nbsp;{""}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={child_category_id}
                              >
                                <option value="">Select Child Category</option>
                                {childCategoryValue?.child_category?.map(
                                  (data) => (
                                    <option value={data?.id} key={data.id}>
                                      {data?.name}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          )}

                          {/* Featured image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Featured Image&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Control
                              type="file"
                              ref={featured_image}
                              onChange={handleFeaturedImgPreview}
                            />
                            <p
                              className="text-primary"
                              style={{ fontSize: "14px" }}
                            >
                              Image Size Should Be 800 x 800. or square size.
                            </p>

                            {files.map((file, key) => {
                              return (
                                <div key={key} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}

                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* product gallery image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Gallery Images
                            </Form.Label>

                            <Form.Control
                              // required
                              multiple
                              type="file"
                              ref={gallery_image}
                              onChange={handleGalleryImgPreview}
                            />
                            <p
                              className="text-primary"
                              style={{ fontSize: "14px" }}
                            >
                              Image Size Should Be 800 x 800. or square size.
                            </p>
                            <div className="row">
                              {files2.map((file, key) => {
                                return (
                                  <div key={key} className="col-md-3 ps-2 py-1">
                                    <span className="Filename">
                                      <img
                                        className="rounded-1"
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please choose an image
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* brand  */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Brand&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={brand_id}
                            >
                              <option value="" selected>
                                Select Brand
                              </option>
                              {addInfoBrand?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Tax  */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Tax&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              ref={tax_id}
                            >
                              <option value="" selected>
                                Select Tax
                              </option>
                              {addInfoTax?.map((data) => (
                                <option value={data?.id} key={data?.id}>
                                  {data.title}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/*short Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Short Description&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              ref={short_description}
                            />
                          </Form.Group>

                          {/*long Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Long Description &nbsp;{" "}
                            </Form.Label>

                            <RichTextEditor
                              joditRef={long_description}
                              getValue={get_long_description_value}
                              value={long_description_value}
                            />
                          </Form.Group>

                          {/* product tags */}
                          <div className="col-12 mb-3">
                            <p className="label fw-bold">
                              Product Tags&nbsp;{""}
                              <span className="text-danger">*</span>
                            </p>
                            <ProductTagInput
                              limit={20}
                              product_tags={product_tags}
                              setproduct_tags={setproduct_tags}
                            />
                          </div>

                          {/* total in stock  */}
                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Total in stock&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="in stock"
                              ref={total_in_stock}
                            />
                          </Form.Group>

                          {/* sku  */}
                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              SKU&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="sku"
                              ref={sku}
                            />
                          </Form.Group>

                          {/* video link  */}
                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Video Link&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="video link"
                              ref={video_link}
                            />
                          </Form.Group>

                          {/* Meta Kewords  */}
                          <div className="col-12 mb-3">
                            <p className="label fw-bold">
                              Meta Kewords&nbsp;{""}
                              <span className="text-danger">*</span>
                            </p>
                            <TagInput
                              limit={20}
                              tags={tags}
                              setTags={setTags}
                            />
                          </div>

                          {/*Meta  Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Meta Description&nbsp;{""}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              ref={meta_description}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductAdd;
